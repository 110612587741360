export const isWeekend = (date) => {
    const day = date.getDay();
    return day === 0 || day === 6;
};

// function to calculate the number of days between two dates excluding weekends
export const calculateBusinessDays = (fromDate, toDate) => {
    let count = 0;
    let currentDate = new Date(fromDate);

    while (currentDate <= toDate) {
        if (!isWeekend(currentDate)) {
            count++;
        }
        currentDate.setDate(currentDate.getDate() + 1);
    }

    return count;
};

export const formatDate = (date) => {
    const d = new Date(date)
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const year = d.getFullYear();
    return `${year}-${month}-${day}`;
};

export function countWeekdaysBetweenDates(fromDate, toDate, holidays) {
    const from = new Date(fromDate);
    const to = new Date(toDate);

    // Normalize the dates (reset hours, minutes, seconds, milliseconds to 0)
    from.setHours(0, 0, 0, 0);
    to.setHours(0, 0, 0, 0);

    let count = 0;

    // Iterate through each day between the two dates
    while (from <= to) {

        const obj = holidays.find(f => {
            return formatDate(f.date) === formatDate(from);
        });
        if (!obj) {

            const day = from.getDay();
            // 0 = Sunday, 6 = Saturday, so skip these days
            if (day !== 0 && day !== 6) {
                console.log(from);

                count++;
            }
        }

        from.setDate(from.getDate() + 1); // Move to the next day
    }

    return count;
}

export function timeToMinutes(time) {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
}

export const minutesToTime = (minutes) => {
    const hrs = Math.floor(minutes / 60);
    const mins = Math.floor(minutes % 60);
    return `${String(hrs).padStart(2, '0')}:${String(mins).padStart(2, '0')}`;
};

export const msToTime = (duration) => {
    var minutes = Math.floor((duration / (1000 * 60)) % 60),
        hours = Math.floor((duration / (1000 * 60 * 60)));

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    return hours + ":" + minutes;
};

export const getShortDay = (date) => {
    const options = { weekday: 'short' };
    return date.toLocaleDateString('en-US', options);
}