import React, { useEffect, useState } from "react";
import {
    Alert,
    Button,
    Col,
    Form,
    Modal,
    Row,
    Table
} from "react-bootstrap";
import api from "../../config/api";
import { getRequestConfig } from "../../utils/getRequestConfig";

// add daily report
const AddEmployee = ({
    showAddEmployee,
    closeAddEmployee,
    addEmployeeHandler,
    departments,
    message
}) => {
    const [employeeId, setEmployeeId] = useState("");
    const [cardNo, setCardNo] = useState("");
    const [name, setName] = useState("");
    const [shiftStartTime, setShiftStartTime] = useState("");
    const [shiftEndTime, setShiftEndTime] = useState("");
    const [departmentId, setDepartmentId] = useState("");

    var today = new Date(),
        // get month in two digit
        currentMonth =
            today.getMonth() + 1 < "10"
                ? "0" + (today.getMonth() + 1)
                : today.getMonth() + 1,
        // get current date
        currentYear = today.getFullYear();

    const [month, setMonth] = useState(currentMonth);
    const [year, setYear] = useState(currentYear);

    //get years
    let rangeYears = [];
    for (let i = 12; i > 0; i--) {
        rangeYears.push(new Date().getFullYear() - i);
    }
    for (let i = 0; i < 12; i++) {
        rangeYears.push(new Date().getFullYear() + i);
    }

    const onSubmitHandler = (event) => {
        event.preventDefault();
        addEmployeeHandler(
            employeeId,
            shiftStartTime,
            shiftEndTime,
            cardNo,
            name,
            departmentId,
            month,
            year
        );
    };

    return (
        <Modal
            show={showAddEmployee}
            onHide={closeAddEmployee}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Add Employee</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={onSubmitHandler} encType="multipart/form-data">
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>Employee Id</Form.Label>
                                <span style={{ color: "red" }}>*</span>
                                <Form.Control
                                    type="number"
                                    placeholder="employee id"
                                    onChange={(event) => setEmployeeId(event.target.value)}
                                    required
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>Employee Card.No</Form.Label>
                                <span style={{ color: "red" }}>*</span>
                                <Form.Control
                                    type="number"
                                    placeholder="cardNo"
                                    onChange={(event) => setCardNo(event.target.value)}
                                    required
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Name</Form.Label>
                                <span style={{ color: "red" }}>*</span>
                                <Form.Control
                                    type="text"
                                    placeholder="name"
                                    onChange={(event) => setName(event.target.value)}
                                    required
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>Shift Start Time</Form.Label>
                                <span style={{ color: "red" }}>*</span>
                                <Form.Control
                                    type="time"
                                    placeholder="Shift Start Time"
                                    onChange={(event) => setShiftStartTime(event.target.value)}
                                    required
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Shift End Time</Form.Label>
                                <span style={{ color: "red" }}>*</span>
                                <Form.Control
                                    type="time"
                                    placeholder="Shift End Time"
                                    onChange={(event) => setShiftEndTime(event.target.value)}
                                    required
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>Department</Form.Label>
                                <span style={{ color: "red" }}>*</span>
                                <Form.Control
                                    as="select"
                                    placeholder="Department"
                                    onChange={(event) => setDepartmentId(event.target.value)}
                                    required
                                >
                                    <option value="">select</option>
                                    {departments.map((department, index) => {
                                        return (
                                            <option key={index} value={department.id}>
                                                {department.departmentName}
                                            </option>
                                        );
                                    })}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>

                    {/* <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>Month</Form.Label>
                                <span style={{ color: "red" }}>*</span>
                                <Form.Control
                                    as="select"
                                    value={month}
                                    onChange={(event) => setMonth(event.target.value)}
                                    required
                                >
                                    <option value="">Select</option>
                                    <option>01</option>
                                    <option>02</option>
                                    <option>03</option>
                                    <option>04</option>
                                    <option>05</option>
                                    <option>06</option>
                                    <option>07</option>
                                    <option>08</option>
                                    <option>09</option>
                                    <option>10</option>
                                    <option>11</option>
                                    <option>12</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Year</Form.Label>
                                <span style={{ color: "red" }}>*</span>
                                <Form.Control
                                    as="select"
                                    value={year}
                                    onChange={(event) => setYear(event.target.value)}
                                    required
                                >
                                    <option value="">Select</option>
                                    {rangeYears.map((rangeYear, index) => {
                                        return <option key={index}>{rangeYear}</option>;
                                    })}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row> */}

                    <hr />
                    {
                        message ? message : ""
                    }
                    <Button type="submit" style={{ borderRadius: "5px", height: '40px', margin: '5px', backgroundColor: "#F47D11", fontWeight: 800, border: "none" }}>
                        Add
                    </Button>
                    <Button
                        style={{ borderRadius: "5px", height: '40px', marginLeft: "25px", backgroundColor: "#F47D11", fontWeight: 800, border: "none" }}
                        onClick={closeAddEmployee}
                    >
                        Close
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

//edit daily report
const EditEmployee = ({
    showEditEmployee,
    closeEditEmployee,
    editEmployeeHandler,
    employee,
    departments,
    message
}) => {

    const [employeeId, setEmployeeId] = useState(employee.id);
    const [shiftStartTime, setShiftStartTime] = useState((employee.shift_time_start));
    const [shiftEndTime, setShiftEndTime] = useState((employee.shift_time_end));
    const [cardNo, setCardNo] = useState(employee.card_number);
    const [name, setName] = useState(employee.name);
    const [status, setStatus] = useState((employee.status).toLowerCase());
    const [departmentId, setDepartmentId] = useState(employee.department_id);

    const role = employee.role ? employee.role : "";

    const onSubmitHandler = (event) => {
        event.preventDefault();
        editEmployeeHandler(
            employeeId,
            shiftStartTime,
            shiftEndTime,
            cardNo,
            name,
            departmentId,
            status
        );
    };
    return (
        <Modal
            show={showEditEmployee}
            onHide={closeEditEmployee}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Edit Employee</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={onSubmitHandler} encType="multipart/form-data">
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>Employee Id</Form.Label>
                                <span style={{ color: "red" }}>*</span>
                                <Form.Control
                                    type="text"
                                    placeholder="employee id"
                                    value={employeeId}
                                    onChange={(event) => setEmployeeId(event.target.value)}
                                    required readOnly
                                />
                            </Form.Group>
                        </Col>

                    </Row>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>Employee Card.No</Form.Label>
                                <span style={{ color: "red" }}>*</span>
                                <Form.Control
                                    type="number"
                                    placeholder="card no"
                                    value={cardNo}
                                    onChange={(event) => setCardNo(event.target.value)}
                                    required
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Name</Form.Label>
                                <span style={{ color: "red" }}>*</span>
                                <Form.Control
                                    type="text"
                                    placeholder="name"
                                    value={name}
                                    onChange={(event) => setName(event.target.value)}
                                    required
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>Shift Start Time</Form.Label>
                                <span style={{ color: "red" }}>*</span>
                                <Form.Control
                                    type="time"
                                    placeholder="Shift Start Time"
                                    value={shiftStartTime}
                                    onChange={(event) => setShiftStartTime(event.target.value)}
                                    required
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Shift End Time</Form.Label>
                                <span style={{ color: "red" }}>*</span>
                                <Form.Control
                                    type="time"
                                    value={shiftEndTime}
                                    placeholder="Shift End Time"
                                    onChange={(event) => setShiftEndTime(event.target.value)}
                                    required
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>Account Status</Form.Label>
                                <span style={{ color: "red" }}>*</span>
                                <Form.Control
                                    as="select"
                                    placeholder="status"
                                    value={status}
                                    onChange={(event) => setStatus(event.target.value)}
                                    required
                                >
                                    <option value="">select</option>
                                    <option value="active">Active</option>
                                    <option value="inactive">Inactive</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Department</Form.Label>
                                <span style={{ color: "red" }}>*</span>
                                <Form.Control
                                    as="select"
                                    placeholder="Department"
                                    value={departmentId}
                                    onChange={(event) => setDepartmentId(event.target.value)}
                                    required
                                >
                                    <option value="">select</option>
                                    {departments.map((department, index) => {
                                        return (
                                            <option key={index} value={department.id}>
                                                {department.departmentName}
                                            </option>
                                        );
                                    })}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>

                    <hr />
                    {
                        message ? message : ""
                    }
                    <Button type="submit" style={{ borderRadius: "5px", height: '40px', margin: '5px', backgroundColor: "#F47D11", fontWeight: 800, border: "none" }}>
                        Update
                    </Button>
                    <Button
                        style={{ borderRadius: "5px", height: '40px', marginLeft: "25px", backgroundColor: "#F47D11", fontWeight: 800, border: "none" }}
                        onClick={closeEditEmployee}
                    >
                        Close
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

// shows all employee daily reports

const EmployeeItems = ({
    index,
    employee,
    departments,
    fetchEmployees,
    setMessage,
    message
}) => {
    const [showEditEmployee, setShowEditEmployee] = useState(false);
    const deIndex = departments.findIndex(d => d.id === employee.department_id)
    let departmentName = deIndex > -1 ? departments[deIndex].departmentName : ''

    const closeEditEmployee = () => {
        setShowEditEmployee(false);
    };
    const openEditEmployee = () => {
        setShowEditEmployee(true);
    };

    const editEmployeeHandler = (
        employeeId,
        shiftStartTime,
        shiftEndTime,
        cardNo,
        name,
        departmentId,
        status
    ) => {
        const editdata = {
            employee_id: employeeId,
            card_number: cardNo,
            name: name,
            department_id: departmentId,
            shift_time_start: shiftStartTime,
            shift_time_end: shiftEndTime,
            status: status
        }

        api.put(`employee/?employeeId=${employeeId}`, editdata, getRequestConfig())
            .then((response) => {
                console.log(response.data);
                if (response.data.data) {
                    setShowEditEmployee(true);
                    fetchEmployees();
                    setMessage(<Alert variant="success">Employee updated successfully</Alert>)
                }

            }).catch((ex) => {
                setMessage(<Alert variant="danger">There is some error</Alert>)
            });
    };
    return (
        <>
            <tr>
                <td className="text-capitalize">{index}</td>
                <td className="text-capitalize">{employee.card_number}</td>
                <td className="text-capitalize">{employee.name}</td>
                <td> {employee.shift_time_start}-{employee.shift_time_end} </td>
                <td className="text-capitalize">{employee.role} </td>
                <td className="text-capitalize">{departmentName} </td>
                <td className="text-capitalize">{employee.status} </td>
                <td>
                    <Button style={{ borderRadius: "5px", height: '40px', marginRight: "10px", backgroundColor: "#F47D11", fontWeight: 800, border: "none" }} onClick={openEditEmployee}>
                        Edit
                    </Button>
                </td>
            </tr>

            <EditEmployee
                showEditEmployee={showEditEmployee}
                closeEditEmployee={closeEditEmployee}
                editEmployeeHandler={editEmployeeHandler}
                employee={employee}
                departments={departments}
                message={message}
            />
        </>
    );
};

const Employee = () => {
    const [employees, setEmployees] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [showAddEmployee, setShowAddEmployee] = useState(false);
    const [message, setMessage] = useState("");
    console.log(departments);
    console.log(employees);

    //get task
    const fetchEmployees = () => {
        api.get("employee/", getRequestConfig()).then((response) => {
            setEmployees(response.data.data);
            console.log(response);
        });
    };
    //get departments
    const fetchDepartments = () => {
        api.get("department/", getRequestConfig()).then((response) => {
            setDepartments(response.data);
        });
    };

    useEffect(() => {
        fetchEmployees();
        fetchDepartments();
    }, []);

    const closeAddEmployee = () => {
        setShowAddEmployee(false);
    };
    const openAddEmployee = () => {
        setShowAddEmployee(true);
    };

    const addEmployeeHandler = (
        employeeId,
        shiftStartTime,
        shiftEndTime,
        cardNo,
        name,
        departmentId,
        month,
        year
    ) => {
        const data = {
            employee_id: employeeId,
            card_number: cardNo,
            name: name,
            department_id: departmentId,
            shift_time_start: shiftStartTime,
            shift_time_end: shiftEndTime,
        }

        api.post("employee/", data, getRequestConfig()).then((response) => {
            if (response.data.data) {
                setShowAddEmployee(false);
                fetchEmployees();
                setMessage(<Alert variant="success">Employee Added Successfully</Alert>)
            }
        }).catch((ex) => {
            setMessage(<Alert variant="danger">There is some error</Alert>)
        });
    };
    setTimeout(() => {
        setMessage("");
    }, 10000);

    return (
        <div>
            <Row>
                <Col sm={12} md={6} lg={6} style={{ padding: "5px" }}>
                    {" "}
                    <h3>Employees</h3>
                </Col>
                <Col sm={12} md={6} lg={6} style={{ padding: "5px" }}>
                    <Button style={{ borderRadius: "5px", height: '40px', margin: '5px', backgroundColor: "#F47D11", fontWeight: 800, border: "none", float: "right" }} onClick={openAddEmployee}>
                        Add
                    </Button>
                </Col>
            </Row>

            <br />

            <Table className="table table-bordered">
                {employees.length === 0 && <div className="fs-5">Loading...</div>}
                <thead>
                    <tr>
                        <th>S.No.</th>
                        <th>Card Id</th>
                        <th>Employee</th>
                        <th>Shift</th>
                        <th>Role</th>
                        <th>Department</th>
                        <th>Status</th>
                        <th>Edit</th>
                    </tr>
                </thead>
                <tbody>
                    {employees.length !== 0 &&
                        employees?.filter((employee) => employee.role !== "admin").map((employee, index) => (
                            <EmployeeItems
                                key={index}
                                index={index + 1}
                                employee={employee}
                                departments={departments}
                                fetchEmployees={fetchEmployees}
                                setMessage={setMessage}
                                message={message}
                            />
                        ))}
                </tbody>
            </Table>
            <AddEmployee
                showAddEmployee={showAddEmployee}
                closeAddEmployee={closeAddEmployee}
                addEmployeeHandler={addEmployeeHandler}
                departments={departments}
                message={message}
            />
        </div>
    );
};
export default Employee;
