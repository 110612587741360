import React, { useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AdminLogin from "./AdminLogin";
import "./App.css";
import Login from "./Login";
import AdminDashboard from "./admin/Dashboard";
import EmployeeDashboard from "./employee/Dashboard";

const App = () => {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("loggedUser")));
    const [admin, setAdmin] = useState(JSON.parse(localStorage.getItem("loggedAdmin")));

    let role = user && JSON.parse(user) ? JSON.parse(user).employee.role : "";
    let path = window.location.href;

    return (
        path.includes('admin')
            ? <Router>
                {admin ? (
                    <AdminDashboard admin={admin} setAdmin={setAdmin} />
                ) : (
                    <AdminLogin setAdmin={setAdmin} />
                    // <Login setUser={setUser} />

                )}

            </Router>
            :
            <Router>
                {role === "employee" ? (
                    <EmployeeDashboard user={user} setUser={setUser} />
                ) : (
                    // <AdminLogin setUser={setUser} />
                    <Login setUser={setUser} />

                )}

            </Router>
        // <Router />
    );
};

export default App;



// import React, { useState } from "react";
// import { Redirect, Route, BrowserRouter as Router, Switch } from "react-router-dom";
// import AdminLogin from "./AdminLogin";
// import Login from "./Login";
// import AdminDashboard from "./admin/Dashboard";
// import EmployeeDashboard from "./employee/Dashboard";
// import GuestDashboard from "./guest/Dashboard"; // Importing the GuestDashboard component

// const App = () => {
//     const [user, setUser] = useState(JSON.parse(localStorage.getItem("loggedUser")));
//     const [admin, setAdmin] = useState(JSON.parse(localStorage.getItem("loggedAdmin")));

//     // Ensure role is safely accessed
//     const role = user && user.employee ? user.employee.role : "";

//     return (
//         <Router>
//             <Switch>
//                 {/* Admin Routes */}
//                 <Route path="/admin/dashboard">
//                     {admin ? (
//                         <AdminDashboard admin={admin} setAdmin={setAdmin} />
//                     ) : (
//                         <AdminLogin setAdmin={setAdmin} />
//                     )}
//                 </Route>

//                 {/* Employee Routes */}
//                 <Route path="/employee/dashboard">
//                     {role === "employee" ? (
//                         <EmployeeDashboard user={user} setUser={setUser} />
//                     ) : (
//                         <Redirect to="/" />
//                     )}
//                 </Route>

//                 {/* Guest Routes */}
//                 <Route path="/guest/dashboard">
//                     {user && role !== "employee" ? (
//                         <GuestDashboard user={user} setUser={setUser} />
//                     ) : (
//                         <Redirect to="/" />
//                     )}
//                 </Route>

//                 {/* Default Route */}
//                 <Route exact path="/">
//                     {!user ? (
//                         <Login setUser={setUser} />
//                     ) : (
//                         <Redirect to={role === "employee" ? "/employee/dashboard" : "/guest/dashboard"} />
//                     )}
//                 </Route>
//             </Switch>
//         </Router>
//     );
// };

// export default App;
