import 'jspdf-autotable';
import { default as React, useEffect, useState } from "react";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import api from "../../config/api";
import { getRequestConfig } from "../../utils/getRequestConfig";

const GuestAttendance = ({ userId }) => {
    const [myAttendance, setMyAttendance] = useState([]);

    var today = new Date(),
        month = (today.getMonth() + 1) < 10 ? "0" + (today.getMonth() + 1) : (today.getMonth() + 1),
        previousday = (today.getDate() - 1) < 10 ? "0" + (today.getDate() - 1) : (today.getDate() - 1),
        currentday = today.getDate() < 10 ? "0" + today.getDate() : today.getDate(),
        yesterday = today.getFullYear() + "-" + month + "-" + previousday;
    today = today.getFullYear() + "-" + month + "-" + currentday;

    const [fromDate, setFromDate] = useState(localStorage.getItem(`fromDate1${userId}`) ? localStorage.getItem(`fromDate1${userId}`) : yesterday);
    const [toDate, setToDate] = useState(localStorage.getItem(`toDate1${userId}`) ? localStorage.getItem(`toDate1${userId}`) : today);

    let totalHoursSpend = 0;

    const fetchAttendance = (fromDate, toDate) => {
        api.get(
            `guestAttendance/report/?startDate=${fromDate}&endDate=${toDate}`,
            getRequestConfig()
        ).then((response) => {
            if (response.data.data) {
                setMyAttendance(response.data.data);
            }
        });
    };

    useEffect(() => {
        fetchAttendance(fromDate, toDate);
    }, [fromDate, toDate]);

    const resetFilter = (yesterday, today) => {
        localStorage.setItem(`fromDate1${userId}`, yesterday);
        setFromDate(localStorage.getItem(`fromDate1${userId}`));
        localStorage.setItem(`toDate1${userId}`, today);
        setToDate(localStorage.getItem(`toDate1${userId}`));
        setMyAttendance([]);
    };

    const sessionHandler = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        if (name === "fromDate") {
            localStorage.setItem(`fromDate1${userId}`, value);
            setFromDate(localStorage.getItem(`fromDate1${userId}`));
        } else if (name === "toDate") {
            localStorage.setItem(`toDate1${userId}`, value);
            setToDate(localStorage.getItem(`toDate1${userId}`));
        }
    };

    return (
        <>
            <Row>
                <Col sm={12} md={6} lg={6} style={{ padding: "5px" }}>
                    <h3>Guest Attendance Report</h3>
                </Col>
            </Row>
            <br />
            <br />

            <Row style={{ fontWeight: "bold" }}>
                <Col>From Date</Col>
                <Col>To Date</Col>
                <Col></Col>
            </Row>
            <Row>
                <Col>
                    <input
                        type="date"
                        className="form-control"
                        value={fromDate}
                        name="fromDate"
                        onChange={sessionHandler}
                        required
                    />
                </Col>
                <Col>
                    <input
                        type="date"
                        className="form-control"
                        value={toDate}
                        name="toDate"
                        onChange={sessionHandler}
                        required
                    />
                </Col>
                <Col>
                    <Button
                        style={{ borderRadius: "5px", height: '40px', marginLeft: '10px', backgroundColor: "#F47D11", fontWeight: 800, border: "none" }}
                        onClick={() => resetFilter(yesterday, today)}
                    >
                        Reset
                    </Button>
                </Col>
            </Row>

            <br />
            {myAttendance.length === 0 &&
                <Card style={{ fontWeight: "650" }}>
                    <Row>
                        <Col>No record found</Col>
                    </Row>
                </Card>
            }
            {myAttendance.length !== 0 && (
                <Card style={{ fontWeight: "650" }}>
                    <Row>
                        <Col>SI NO</Col>
                        <Col>Date</Col>
                        <Col>Check-in</Col>
                        <Col>Check-out</Col>
                        <Col>Name</Col>
                        <Col>Phone Number</Col>
                        <Col>Company</Col>
                        <Col>Expected Hours</Col>
                        <Col></Col>
                    </Row>
                </Card>
            )}
            {myAttendance.length !== 0 && myAttendance?.map((item, index) => {

                let checkinDate = item.checkin !== null ? new Date(item.checkin) : "";
                let checkoutDate = item.checkout !== null ? new Date(item.checkout) : "";
                let timeDifference = (item.checkin !== null && item.checkout !== null) ? Math.abs(
                    checkoutDate.getTime() - checkinDate.getTime()
                ) : 0;
                let hours = (timeDifference / (1000 * 3600)).toFixed(2);

                totalHoursSpend += Number(hours);

                return <GuestAttendanceItem
                    guest={item}
                    key={index}
                    serialNo={index + 1}
                    fetchAttendance={fetchAttendance}
                    fromDate={fromDate}
                    toDate={toDate}
                />;
            })}
        </>
    );
};

const GuestAttendanceItem = ({ guest, serialNo, fetchAttendance, fromDate, toDate }) => {
    const [showEditAttendance, setShowEditAttendance] = useState(false);
    const [message, setMessage] = useState("");

    const closeEditAttendance = () => setShowEditAttendance(false);

    const editAttendanceHandler = (guestId, date, checkin, checkout, name, phoneNumber, company, expectedHours, status) => {
        let content = {
            date: date,
            checkin: checkin,
            checkout: checkout,
            name: name,
            mobile_number: phoneNumber,
            company: company,
            expected_hour: expectedHours,
            status: status
        }
        api.put(
            `guestAttendance/?id=${guestId}`,
            content,
            getRequestConfig()
        ).then((response) => {
            setMessage("Attendance updated successfully!");
            fetchAttendance(fromDate, toDate);
            closeEditAttendance();
        });
    };

    const checkinDate = guest.checkin ? new Date(guest.checkin) : "";
    const checkoutDate = guest.checkout ? new Date(guest.checkout) : "";
    const timeDifference = guest.checkin && guest.checkout
        ? Math.abs(checkoutDate.getTime() - checkinDate.getTime())
        : 0;
    const hours = (timeDifference / (1000 * 3600)).toFixed(2);

    return (
        <Card style={{ fontWeight: "400" }}>
            <Row>
                <Col>{serialNo}</Col>
                <Col>{guest.date}</Col>
                {/* <Col>{guest.checkin}</Col>
                <Col>{guest.checkout}</Col> */}
                <Col>{checkinDate.getHours() + ":" + checkinDate.getMinutes()}</Col>
                <Col>{checkoutDate.getHours() + ":" + checkoutDate.getMinutes()}</Col>
                <Col>{guest.name}</Col>
                <Col>{guest.mobile_number}</Col>
                <Col>{guest.company}</Col>
                <Col>{guest.expected_hour}</Col>
                <Col>
                    <Button
                        style={{ borderRadius: "5px", height: '40px', margin: '10px', backgroundColor: "#F47D11", fontWeight: 800, border: "none" }}
                        onClick={() => setShowEditAttendance(true)}
                    >
                        Edit
                    </Button>
                </Col>
            </Row>
            {showEditAttendance && (
                <EditGuestAttendance
                    showEditAttendance={showEditAttendance}
                    closeEditAttendance={closeEditAttendance}
                    editAttendanceHandler={editAttendanceHandler}
                    guest={guest}
                    message={message}
                />
            )}
        </Card>
    );
};


const EditGuestAttendance = ({
    showEditAttendance,
    closeEditAttendance,
    editAttendanceHandler,
    guest,
    message,
}) => {
    const [date, setDate] = useState(guest.date);
    const [checkin, setCheckin] = useState(guest.checkin);
    const [checkout, setCheckout] = useState(guest.checkout);
    const [name, setName] = useState(guest.name);
    const [phoneNumber, setPhoneNumber] = useState(guest.mobile_number);
    const [company, setCompany] = useState(guest.company);
    const [expectedHours, setExpectedHours] = useState(guest.expected_hour);
    const [status, setStatus] = useState(guest.status);

    const guestId = guest.id;

    const onSubmitHandler = (e) => {
        e.preventDefault();
        editAttendanceHandler(guestId, date, checkin, checkout, name, phoneNumber, company, expectedHours, status);
    };

    return (
        <Modal show={showEditAttendance} onHide={closeEditAttendance} centered>
            <Modal.Header closeButton>
                <Modal.Title>Edit Guest Attendance</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {message && <p className="text-danger">{message}</p>}
                <Form onSubmit={onSubmitHandler}>
                    <Form.Group>
                        <Form.Label>Date</Form.Label>
                        <Form.Control
                            type="date"
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                            placeholder="Enter Date"
                            required
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Check-in</Form.Label>
                        <Form.Control
                            type="text"
                            value={checkin}
                            onChange={(e) => setCheckin(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Check-out</Form.Label>
                        <Form.Control
                            type="text"
                            value={checkout}
                            onChange={(e) => setCheckout(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Enter guest name"
                            required
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control
                            type="text"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            placeholder="Enter phone number"
                            required
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Company</Form.Label>
                        <Form.Control
                            type="text"
                            value={company}
                            onChange={(e) => setCompany(e.target.value)}
                            placeholder="Enter company name"
                            required
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Expected Hours</Form.Label>
                        <Form.Control
                            type="number"
                            value={expectedHours}
                            onChange={(e) => setExpectedHours(e.target.value)}
                            placeholder="Enter expected hours"
                            required
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Status</Form.Label>
                        <span style={{ color: "red" }}>*</span>
                        <Form.Control
                            as="select"
                            value={status}
                            onChange={(event) => setStatus(event.target.value)}
                        >
                            <option value="">select</option>
                            <option>checkedin</option>
                            <option>checkedout</option>
                        </Form.Control>
                    </Form.Group>
                    <div className="d-flex justify-content-end">
                        <Button
                            style={{ borderRadius: "5px", height: '40px', margin: '10px', backgroundColor: "#F47D11", fontWeight: 800, border: "none" }}
                            onClick={closeEditAttendance}
                            className="me-2"
                        >
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            style={{ borderRadius: "5px", height: '40px', margin: '10px', backgroundColor: "#F47D11", fontWeight: 800, border: "none" }}
                        >
                            Save
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default GuestAttendance;
