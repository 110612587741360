import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import api from "../../config/api";
import { calculateBusinessDays, formatDate, minutesToTime, timeToMinutes } from "../../functions";
import { getRequestConfig } from "../../utils/getRequestConfig";
import Header from "../Header";

const Dashboard = ({ user, setUser }) => {
    let employee = user && JSON.parse(user) ? JSON.parse(user).employee : {};
    const [myAttendance, setMyAttendance] = useState([]);
    const [cumulativeData, setCumulativeData] = useState([]);
    const [timer, setTimer] = useState(new Date());
    const [checkinFlag, setCheckinFlag] = useState(true);
    const [message, setMessage] = useState("");
    const [date, setDate] = useState("");
    const [showInputField, setShowInputField] = useState(false);
    const [selection, setSelection] = useState("Dashboard");
    const [isCheckoutDisabled, setIsCheckoutDisabled] = useState(false);
    const [showCheckinModal, setShowCheckinModal] = useState(false);
    const [showCheckoutModal, setShowCheckoutModal] = useState(false);
    const [showCheckoutReasonModal, setShowCheckoutReasonModal] = useState(false);
    const [selectedReason, setSelectedReason] = useState(""); // Updated to handle dropdown selection
    const [totalExpectedHours, setTotalExpectedHours] = useState("");

    const history = useHistory();

    useEffect(() => {
        fetchAttendances();
        fetchAttendanceForActualHours();
        runTimer();
    }, []);

    const checkinDate = myAttendance[0] && myAttendance[0].checkin ? new Date(myAttendance[0].checkin) : null;
    const checkoutDate = myAttendance[0] && myAttendance[0].checkout ? new Date(myAttendance[0].checkout) : null;

    const fetchAttendanceForActualHours = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = today.getMonth();
        const date = today.getDate();

        let fromDate;
        let toDate;
        let empId = employee.id;

        if (date >= 16) {
            fromDate = new Date(year, month, 16); // 16th of current month
            toDate = today;
        } else {
            fromDate = new Date(year, month - 1, 16); // 16th of previous month
            toDate = today;
        }

        const timeDiff = Math.abs((new Date(toDate)).getTime() - (new Date(fromDate)).getTime());
        const dayDiff = Math.ceil(timeDiff / (1000 * 3600 * 24)) + 1;

        const days = calculateBusinessDays(fromDate, toDate);
        const startMinutes = timeToMinutes(employee.shift_time_start);
        const endMinutes = timeToMinutes(employee.shift_time_end);

        let gapMinutes = endMinutes - startMinutes;
        if (gapMinutes < 0) {
            gapMinutes += 24 * 60; // Adjust for cases where endtime is on the next day
        }
        var totalMinutes = gapMinutes * days;
        const totalExpectedTime = minutesToTime(totalMinutes);
        setTotalExpectedHours(totalExpectedTime);

        if (empId !== "" && fromDate !== "" && toDate !== "") {
            api.get(`attendance/report/?fromDate=${formatDate(fromDate)}&toDate=${formatDate(toDate)}&employeeId=${empId}`, getRequestConfig()
            ).then((response) => {
                if (response) {
                    setCumulativeData(response.data);
                    console.log(response.data);
                }
            });
        }

    };

    const fetchAttendances = () => {
        api.get("attendance/", getRequestConfig()).then((response) => {
            const data = response.data;
            setMyAttendance(data);
            setCheckinFlag(true);
            data.forEach((item) => {
                if (item.status === "checkedin") {
                    setCheckinFlag(false);
                }
            });
        });
    };

    const checkoutHandler = (attendanceId) => {
        var tempDate = new Date();
        var date = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate() + ' ' + tempDate.getHours() + ':' + tempDate.getMinutes() + ':' + tempDate.getSeconds();

        let content = { checkout: date, status: "checkedout" };
        api
            .put(`attendance/?id=${attendanceId}`, content, getRequestConfig())
            .then((response) => {
                fetchAttendances();
                setMessage(<Alert variant="success">Checkout Successfully</Alert>);
                setIsCheckoutDisabled(true);
                setShowCheckoutModal(true);
            }).catch((ex) => {
                setMessage(<Alert variant="danger">There is some error</Alert>);
            });
    };

    const checkinHandler = () => {
        var tempDate = new Date();
        var date = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate() + ' ' + tempDate.getHours() + ':' + tempDate.getMinutes() + ':' + tempDate.getSeconds();

        let content = { checkin: date, status: "checkedin" };
        api
            .post(`attendance/`, content, getRequestConfig())
            .then((response) => {
                fetchAttendances();
                setMessage(<Alert variant="success">Checkin Successfully</Alert>);
                setShowCheckinModal(true);
            }).catch((ex) => {
                setMessage(<Alert variant="danger">There is some error</Alert>);
            });
    };

    const applyLeaveHandler = () => {
        if (date !== '') {
            let content = { employee_id: JSON.parse(user).employee.id, date: date, code: "1105" };

            api.post(`employeeLeave/`, content, getRequestConfig())
                .then((response) => {
                    if (response.status.code === 200 || response.data.data) {
                        setDate('');
                        setShowInputField(false);
                        setMessage(<Alert variant="success">Leave applied successfully</Alert>);
                    }
                }).catch((ex) => {
                    setMessage(<Alert variant="danger">There is some error</Alert>);
                });
        } else {
            setMessage(<Alert variant="danger">Select date!</Alert>);
        }
    };

    const runTimer = () => {
        setInterval(() => setTimer(new Date()), 1000);
    };

    setTimeout(() => {
        setMessage("");
    }, 10000);

    const handleCheckinOk = () => {
        setShowCheckinModal(false);
        localStorage.removeItem("loggedUser");
        setUser("");
        history.push('/logout');
    };

    const handleCheckoutOk = () => {
        setShowCheckoutModal(false);
        localStorage.removeItem("loggedUser");
        setUser("");
        history.push('/logout');
    };

    const handleCheckoutWithReasonOk = () => {
        const attendanceId = myAttendance[0]?.id;
        console.log("hi 1");

        if (attendanceId && selectedReason) {
            console.log("hi 2");
            var tempDate = new Date();
            var date = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate() + ' ' + tempDate.getHours() + ':' + tempDate.getMinutes() + ':' + tempDate.getSeconds();
            let content = { checkout: date, status: "checkedout", comment: selectedReason }; // Use `selectedReason` for the reason
            api
                .put(`attendance/?attendanceId=${attendanceId}`, content, getRequestConfig())
                .then((response) => {
                    fetchAttendances();
                    setMessage(<Alert variant="success">Checkout with Reason Successfully</Alert>);
                    setIsCheckoutDisabled(true);
                    setShowCheckoutReasonModal(false);
                }).catch((ex) => {
                    setMessage(<Alert variant="danger">There is some error</Alert>);
                });
        }
    };

    const formatTime = (time) => {
        const [hours, minutes] = time.split(':');
        const date = new Date();
        date.setHours(hours, minutes);
        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
    };

    const calculateBalanceHours = () => {
        const expectedMinutes = timeToMinutes(cumulativeData.length > 0 ? cumulativeData[0].totalExpectedHours : '00:00');
        const actualMinutes = timeToMinutes(cumulativeData.length > 0 && cumulativeData[0].totalActualHours ? cumulativeData[0].totalActualHours : '00:00');

        const balanceMinutes = expectedMinutes - actualMinutes;
        return minutesToTime(balanceMinutes);
    };

    return (
        <>
            <Header user={user} setUser={setUser} />
            <div className="mx-3 mt-3">
                <h3>
                    {timer.toLocaleDateString()} {timer.toLocaleTimeString()}
                </h3>

                <div className="card my-3 shadow p-3 mb-5 bg-body rounded">
                    <div className="card-body">
                        <h4>{employee.name}</h4>
                        <h6>
                            Shift Timing: {formatTime(employee.shift_time_start)} - {formatTime(employee.shift_time_end)}
                        </h6>
                        <h6 className="text-capitalize">Department: {employee.department_name}</h6>
                        {checkinDate ? (
                            <h6 className="text-capitalize">
                                Check-in Time: {checkinDate ? checkinDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : ''}
                                {checkoutDate ? ' - ' + checkoutDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : ''}
                            </h6>
                        ) : (
                            <h6 className="text-capitalize">Check-in Time: Not available</h6>
                        )}
                    </div>
                </div>

                <Row>
                    <Col>
                        {checkinFlag ? (
                            <Button style={{ borderRadius: "5px", height: '40px', margin: '5px', backgroundColor: "#F47D11", fontWeight: 800, border: "none" }} onClick={checkinHandler}>CHECK - IN</Button>
                        ) : (
                            <Button className={`btn-secondary disabled`}>CHECK - IN</Button>
                        )}
                    </Col>
                    <Col>
                        {checkinFlag ? (
                            <Button className={`btn-secondary disabled`}>CHECK - OUT</Button>
                        ) : (
                            myAttendance.length !== 0 && (
                                <Button
                                    style={{ borderRadius: "5px", height: '40px', margin: '5px', backgroundColor: "#F47D11", fontWeight: 800, border: "none" }}
                                    onClick={() => checkoutHandler(myAttendance[0].id)}
                                    disabled={isCheckoutDisabled}
                                >
                                    CHECK - OUT
                                </Button>
                            )
                        )}
                    </Col>
                    <Col>
                        {checkinFlag ? (
                            <Button className={`btn-secondary disabled`}>CHECK - OUT WITH REASON</Button>
                        ) : (
                            myAttendance.length !== 0 && (
                                <Button
                                    style={{ borderRadius: "5px", height: '55px', margin: '5px', backgroundColor: "#F47D11", fontWeight: 800, border: "none" }}
                                    onClick={() => setShowCheckoutReasonModal(true)}
                                    disabled={isCheckoutDisabled}
                                >
                                    CHECK - OUT WITH REASON
                                </Button>
                            )
                        )}
                    </Col>
                    {showInputField && (
                        <>
                            <Col className="m-1">
                                <input
                                    type="date"
                                    value={date}
                                    onChange={(e) => setDate(e.target.value)}
                                    className="form-control"
                                />
                            </Col>
                            <Col>
                                <Button onClick={applyLeaveHandler} style={{ borderRadius: "5px", height: '40px', margin: '5px', backgroundColor: "#F47D11", fontWeight: 800, border: "none" }}>SAVE</Button>
                            </Col>
                        </>
                    )}
                </Row>

                <br />

                {/* {message && message} */}
            </div>

            <Modal show={showCheckinModal} onHide={() => setShowCheckinModal(false)}>
                <Modal.Header>
                    <Modal.Title>Check-in Successfully</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* <div>Expected Hours : {totalExpectedHours ? totalExpectedHours : '00:00'}</div>
                    <div>Actual Hours : {cumulativeData.length > 0 ? cumulativeData[0].totalActualHours : '00:00'}</div> */}
                    <div>Balance Working Hours: {calculateBalanceHours()}</div>
                </Modal.Body>
                <Modal.Footer>
                    <Button style={{ borderRadius: "5px", height: '40px', margin: '5px', backgroundColor: "#F47D11", fontWeight: 800, border: "none" }} onClick={handleCheckinOk}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showCheckoutModal} onHide={() => setShowCheckoutModal(false)}>
                <Modal.Header >
                    <Modal.Title>Check-out Successfully</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* <div>Expected Hours : {cumulativeData.length > 0 ? cumulativeData[0].totalExpectedHours : '00:00'}</div>
                    <div>Actual Hours : {cumulativeData.length > 0 ? cumulativeData[0].totalActualHours : '00:00'}</div> */}
                    <div>Balance Working Hours: {calculateBalanceHours()}</div>
                </Modal.Body>
                <Modal.Footer>
                    <Button style={{ borderRadius: "5px", height: '40px', margin: '5px', backgroundColor: "#F47D11", fontWeight: 800, border: "none" }} onClick={handleCheckoutOk}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showCheckoutReasonModal} onHide={() => setShowCheckoutReasonModal(false)}>
                <Modal.Header>
                    <Modal.Title>Check-out with Reason</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* <div>Expected Hours : {cumulativeData.length > 0 ? cumulativeData[0].totalExpectedHours : '00:00'}</div>
                    <div>Actual Hours : {cumulativeData.length > 0 ? cumulativeData[0].totalActualHours : '00:00'}</div> */}
                    <div>Balance Working Hours: {calculateBalanceHours()}</div>
                    <br />
                    <Form>
                        <Form.Group controlId="formReason">
                            <Form.Label style={{ fontWeight: 600 }}>Code With Reason</Form.Label>
                            <Form.Control as="select" value={selectedReason} onChange={(e) => setSelectedReason(e.target.value)}>
                                <option value="">Select Reason</option>
                                <option value="101">101 - HOLIDAY</option>
                                <option value="102">102 - LEAVE</option>
                                <option value="103">103 - SICK</option>
                                <option value="104">104 - BUSINESS VISIT</option>
                            </Form.Control>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        style={{ borderRadius: "5px", height: '40px', margin: '5px', backgroundColor: "#F47D11", fontWeight: 800, border: "none" }}
                        onClick={handleCheckoutWithReasonOk}
                    >
                        CHECK - OUT
                    </Button>
                    <Button style={{ borderRadius: "5px", height: '40px', margin: '5px', backgroundColor: "#F47D11", fontWeight: 800, border: "none" }} onClick={() => setShowCheckoutReasonModal(false)}>
                        CANCEL
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default Dashboard;




// import React, { useEffect, useState } from "react";
// import { Alert, Button, Col, Row } from "react-bootstrap";
// import api from "../../config/api";
// import { getRequestConfig } from "../../utils/getRequestConfig";
// import Header from "../Header";

// const Dashboard = ({ user, setUser }) => {
//     let employee = user && JSON.parse(user) ? JSON.parse(user).employee : {}
//     const [myAttendance, setMyAttendance] = useState([]);
//     const [timer, setTimer] = useState(new Date());
//     const [checkinFlag, setCheckinFlag] = useState(true);
//     const [message, setMessage] = useState("")
//     const [date, setDate] = useState("")
//     const [showInputField, setShowInputField] = useState(false)
//     const [selection, setSelection] = useState("Dashboard");

//     useEffect(() => {
//         fetchAttendances();
//         runTimer();
//     }, []);

//     const fetchAttendances = () => {
//         api.get("attendance/", getRequestConfig()).then((response) => {
//             console.log(response.data);
//             const data = response.data;
//             setMyAttendance(response.data);
//             setCheckinFlag(true);
//             data.map((item, index) => {
//                 if (item.status === "checkedin") {
//                     setCheckinFlag(false);
//                 }
//             });
//         });
//     };

//     const checkoutHandler = (attendanceId) => {
//         var tempDate = new Date();
//         var date = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate() + ' ' + tempDate.getHours() + ':' + tempDate.getMinutes() + ':' + tempDate.getSeconds();

//         let content = { checkout: date, status: "checkedout" };
//         api
//             .put(`attendance/?id=${attendanceId}`, content, getRequestConfig())
//             .then((response) => {
//                 fetchAttendances();
//                 setMessage(<Alert variant="success">Checkout Successfully</Alert>)

//             }).catch((ex) => {
//                 setMessage(<Alert variant="danger">There is some error</Alert>)
//             });
//     };

//     const checkinHandler = () => {
//         var tempDate = new Date();
//         var date = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate() + ' ' + tempDate.getHours() + ':' + tempDate.getMinutes() + ':' + tempDate.getSeconds();

//         let content = { checkin: date, status: "checkedin" };
//         api
//             .post(`attendance/`, content, getRequestConfig())
//             .then((response) => {
//                 fetchAttendances();
//                 setMessage(<Alert variant="success">Checkin Successfully</Alert>)

//             }).catch((ex) => {
//                 setMessage(<Alert variant="danger">There is some error</Alert>)
//             });
//     };

//     const applyLeaveHandler = () => {
//         if (date != '') {

//             let content = { employee_id: JSON.parse(user).employee.id, date: date, code: "1105" };

//             api.post(`employeeLeave/`, content, getRequestConfig())
//                 .then((response) => {
//                     if (response.status.code === 200 || response.data.data) {
//                         setDate('')
//                         setShowInputField(false)
//                         setMessage(<Alert variant="success">Leave applied successfully</Alert>)
//                     }

//                 }).catch((ex) => {
//                     setMessage(<Alert variant="danger">There is some error</Alert>)
//                 });
//         } else setMessage(<Alert variant="danger">Select date!</Alert>)
//     };

//     const runTimer = () => {
//         setInterval(() => setTimer(new Date()), 1000);
//     };

//     setTimeout(() => {
//         setMessage("");

//     }, 10000);
//     return (
//         <>
//             <Header user={user} setUser={setUser} />
//             <div className="mx-3 mt-3">
//                 <h3>
//                     {timer.toLocaleDateString()} {timer.toLocaleTimeString()}
//                 </h3>

//                 <div className="card my-3 shadow p-3 mb-5 bg-body rounded ">
//                     <div className="card-body">
//                         <h4>{employee.name}</h4>
//                         <h6>Shif Timing: {employee.shift_time_start} - {employee.shift_time_end}</h6>
//                         <h6 className="text-capitalize">Department: {employee.department_name}</h6>
//                         {/* <h6 className="text-capitalize">Status: {employee.status}</h6> */}
//                     </div>
//                 </div>

//                 <Row>
//                     <Col>
//                         {checkinFlag ? (
//                             <Button onClick={checkinHandler}>Check-in</Button>
//                         ) : (
//                             <Button className="btn-secondary disabled">Check-in</Button>
//                         )}
//                     </Col>
//                     <Col>
//                         {checkinFlag === false ? (
//                             <AttendanceItem
//                                 checkoutHandler={checkoutHandler}
//                             />
//                         ) : (
//                             <Button className="btn-secondary disabled">Check-out</Button>
//                         )}
//                     </Col>

//                <Col>
//                      <Button onClick={() => setShowInputField(!showInputField)} className="btn-primary-disabled">Leave</Button>
//                   </Col>
//                     {showInputField && <Col className="m-1">
//                         <input type="date" value={date} onChange={(e) => {
//                             setDate(e.target.value)
//                         }} className="form-control" />
//                     </Col>}
//                     {showInputField && <Col>
//                         <Button onClick={applyLeaveHandler} className="btn-sm btn-success">Save</Button>
//                     </Col>}
//                 </Row>

//                 <br />

//                 {
//                     message ? message : ""
//                 }
//                 <div className="mb-2">
//                     <Card style={{ fontWeight: "650" }}>
//                         <Row>
//                             <Col>DATE</Col>
//                             <Col>IN</Col>
//                             <Col>OUT</Col>
//                         </Row>
//                     </Card>
//                     {
//                         myAttendance.length === 0 && <h4>Loading...</h4>
//                     }
//                     {myAttendance.length !== 0 && myAttendance.map((item, index) => {
//                         return (
//                             <AttendanceItem
//                                 attendance={item}
//                                 key={index}
//                                 checkoutHandler={checkoutHandler}
//                             />
//                         );
//                     })}
//                 </div>
//             </div>
//         </>
//     );
// };

// const AttendanceItem = ({ attendance, checkoutHandler }) => {
//     const cardStyle = attendance.status === "checkedin" ? { backgroundColor: "#FFDE80" } : {};
//     const checkinDate = new Date(attendance.checkin);
//     const dateString = checkinDate.getDate() + "-" +
//         (checkinDate.getMonth() + 1) + "-" + checkinDate.getFullYear();
//     const checkoutDate = attendance.checkout ? new Date(attendance.checkout) : "";

//     return (
//         <>
//             <Button onClick={() => checkoutHandler(attendance.id)}>
//                 Checkout
//             </Button>
//         </>
//         <Card style={cardStyle}>
//             <Row>
//                 <Col>{dateString}</Col>
//                 <Col style={{ fontWeight: 'bold' }}>{checkinDate.getHours() + ":" + checkinDate.getMinutes()}</Col>
//                 <Col style={{ fontWeight: 'bold' }}>
//                     {attendance.checkout
//                         ? (
//                             checkoutDate.getHours() + ":" + checkoutDate.getMinutes()
//                         )
//                         : (
//                             <Button onClick={() => checkoutHandler(attendance.id)}>
//                                 Checkout
//                             </Button>
//                         )}
//                 </Col>
//             </Row>
//         </Card>
//     );
// };

// export default Dashboard;

// const Dashboard = ({ user, setUser }) => {
//     const [selection, setSelection] = useState("Dashboard");
//     const userId = JSON.parse(user).employee.employee_id;
//     const userId = "catharin"
//     return (
//         <>
//             <Header user={user} setUser={setUser} />

//             <Row>
//                 <Col lg={1} sm={2} md={2} className="side-nav px-2" style={{ textAlign: "center" }}>
//                     <Nav defaultActiveKey="/home" className="flex-column">
//                         <Link
//                             to="/home"
//                             className={selection === "Dashboard" ? "text current" : "text"}
//                             onClick={() => setSelection("Dashboard")}
//                         >
//                             <i className="fa fa-tachometer fa-3x"></i><br />
//                             <span>Dashboard</span>
//                         </Link>
//                         <Link
//                             to="/attendance"
//                             className={selection === "Attendance" ? "text current" : "text"}
//                             onClick={() => setSelection("Attendance")}
//                         >
//                             <i className="fa fa-check-square-o fa-3x"></i><br />
//                             <span>Attendance</span>
//                         </Link>

//                     </Nav>
//                 </Col>
//                 <Col lg={11} sm={10} md={10} className="main">
//                     <Switch>
//                         <Route exact path="/home" component={Home} />
//                         <Route exact path="/attendance" component={Attendance} />
//                     </Switch>
//                 </Col>
//             </Row>
//         </>
//     );
// };
// export default Dashboard;
