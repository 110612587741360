import 'jspdf-autotable';
import { default as React, useEffect, useState } from "react";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import api from "../../config/api";
import { getRequestConfig } from "../../utils/getRequestConfig";

const Leave = ({ userId }) => {
    const [myAttendance, setMyAttendance] = useState([]);
    const [employees, setEmployees] = useState([]);

    var today = new Date(),
        month = (today.getMonth() + 1) < 10 ? "0" + (today.getMonth() + 1) : (today.getMonth() + 1),
        previousday = (today.getDate() - 1) < 10 ? "0" + (today.getDate() - 1) : (today.getDate() - 1),
        currentday = today.getDate() < 10 ? "0" + today.getDate() : today.getDate(),
        yesterday = today.getFullYear() + "-" + month + "-" + previousday;
    today = today.getFullYear() + "-" + month + "-" + currentday;

    const [fromDate, setFromDate] = useState(localStorage.getItem(`fromDate1${userId}`) ? localStorage.getItem(`fromDate1${userId}`) : yesterday);
    const [toDate, setToDate] = useState(localStorage.getItem(`toDate1${userId}`) ? localStorage.getItem(`toDate1${userId}`) : today);
    const [employee, setEmployee] = useState(localStorage.getItem(`employee1${userId}`) ? localStorage.getItem(`employee1${userId}`) : "");
    let totalHoursSpend = 0;

    const resetFilter = (yesterday, today) => {

        localStorage.setItem(`employee1${userId}`, "");
        setEmployee(localStorage.getItem(`employee1${userId}`));
        localStorage.setItem(`fromDate1${userId}`, yesterday);
        setFromDate(localStorage.getItem(`fromDate1${userId}`));
        localStorage.setItem(`toDate1${userId}`, today);
        setToDate(localStorage.getItem(`toDate1${userId}`));
        setMyAttendance([]);
    };

    const sessionHandler = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        if (name === "employee") {
            localStorage.setItem(`employee1${userId}`, value);
            setEmployee(localStorage.getItem(`employee1${userId}`));
        }
        else if (name === "fromDate") {
            localStorage.setItem(`fromDate1${userId}`, value);
            setFromDate(localStorage.getItem(`fromDate1${userId}`));
        }
        else if (name === "toDate") {
            localStorage.setItem(`toDate1${userId}`, value);
            setToDate(localStorage.getItem(`toDate1${userId}`));
        }

    }
    const fetchLeave = (employee, fromDate, toDate) => {

        if (employee !== "" && fromDate !== "" && toDate !== "") {
            api.get(`employeeLeave/?startDate=${fromDate}&endDate=${toDate}&employeeId=${employee}`,
                getRequestConfig()
            ).then((response) => {
                setMyAttendance(response.data.data);
                console.log(response.data.data);
            });
        }

    };

    useEffect(() => {
        fetchLeave(employee, fromDate, toDate);
    }, [fromDate, toDate, employee])

    useEffect(() => {
        fetchEmployees();
    }, []);

    const fetchEmployees = () => {
        api.get("employee/", getRequestConfig()).then((response) => {
            setEmployees(response.data.data);
        });
    };

    return (
        <>
            <Row>
                <Col sm={12} md={6} lg={6} style={{ padding: "5px" }}>
                    <h3>Leave Report</h3>
                </Col>
            </Row>
            <br />
            <br />

            <Row style={{ fontWeight: "bold" }}>
                <Col>Employee</Col>
                <Col>From Date</Col>
                <Col>To Date</Col>
                <Col></Col>
            </Row>
            <Row>
                <Col>

                    <select
                        className="form-control"
                        required
                        name="employee"
                        onChange={sessionHandler}
                        value={employee}
                    >
                        <option value="">Select Employee</option>
                        {employees?.filter((item) => {
                            if (item.role !== "admin") return item;
                        }).map((item, index) => {
                            return (
                                <option key={index} value={item.id}>
                                    {item.name}
                                </option>
                            );
                        })}
                    </select>
                </Col>
                <Col>
                    <input
                        type="date"
                        className="form-control"
                        value={fromDate}
                        name="fromDate"
                        onChange={sessionHandler}
                        required
                    />
                </Col>
                <Col>
                    <input
                        type="date"
                        className="form-control"
                        value={toDate}
                        name="toDate"
                        onChange={sessionHandler}
                        required
                    />
                </Col>
                <Col>
                    <Button
                        style={{ borderRadius: "5px", height: '40px', marginLeft: '10px', backgroundColor: "#F47D11", fontWeight: 800, border: "none" }}
                        onClick={() => resetFilter(yesterday, today)}
                    >
                        Reset
                    </Button>
                </Col>
            </Row>

            <br />
            {myAttendance.length === 0 &&
                <Card style={{ fontWeight: "650" }}>
                    <Row>
                        <Col>No record found</Col>
                    </Row>
                </Card>
            }
            {myAttendance.length !== 0 && (
                <Card style={{ fontWeight: "650" }}>
                    <Row>
                        <Col>SI NO</Col>
                        <Col>Date</Col>
                        <Col>Code</Col>
                        <Col></Col>
                        <Col></Col>
                    </Row>
                </Card>
            )}
            {myAttendance.length !== 0 && myAttendance?.map((item, index) => {

                let checkinDate = item.checkin !== null ? new Date(item.checkin) : "";
                let checkoutDate = item.checkout !== null ? new Date(item.checkout) : "";
                let timeDifference = (item.checkin !== null && item.checkout !== null) ? Math.abs(
                    checkoutDate.getTime() - checkinDate.getTime()
                ) : 0;
                let hours = (timeDifference / (1000 * 3600)).toFixed(2);

                totalHoursSpend += Number(hours);

                return <LeaveAttendanceItem
                    leave={item}
                    key={index}
                    serialNo={index + 1}
                    fetchLeave={fetchLeave}
                    fromDate={fromDate}
                    toDate={toDate}
                    employeeId={employee}
                />;
            })}
        </>
    );
};

const LeaveAttendanceItem = ({ leave, serialNo, fetchLeave, fromDate, toDate, employeeId }) => {
    const [showEditAttendance, setShowEditAttendance] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [message, setMessage] = useState("");

    const closeEditAttendance = () => setShowEditAttendance(false);
    const closeDeleteConfirmation = () => setShowDeleteConfirmation(false);

    const editAttendanceHandler = (leaveId, date, code) => {
        let content = {
            date: date,
            code: code
        }
        api.put(
            `employeeLeave/?id=${leaveId}`,
            content,
            getRequestConfig()
        ).then((response) => {
            setMessage("Leave updated successfully!");
            fetchLeave(employeeId, fromDate, toDate);
            closeEditAttendance();
        });
    };

    const deleteAttendanceHandler = (leaveId) => {
        api.delete(
            `employeeLeave/?id=${leaveId}`,
            getRequestConfig()
        ).then((response) => {
            setMessage("Leave deleted successfully!");
            fetchLeave(employeeId, fromDate, toDate);
            closeDeleteConfirmation();
        });
    };

    const checkinDate = leave.checkin ? new Date(leave.checkin) : "";
    const checkoutDate = leave.checkout ? new Date(leave.checkout) : "";
    const timeDifference = leave.checkin && leave.checkout
        ? Math.abs(checkoutDate.getTime() - checkinDate.getTime())
        : 0;
    const hours = (timeDifference / (1000 * 3600)).toFixed(2);

    return (
        <Card style={{ fontWeight: "400" }}>
            <Row>
                <Col>{serialNo}</Col>
                <Col>{leave.date}</Col>
                <Col>{leave.code}</Col>
                <Col>
                    <Button
                        style={{ borderRadius: "5px", height: '30px', margin: '8px', backgroundColor: "#F47D11", fontWeight: 800, border: "none" }}
                        onClick={() => setShowEditAttendance(true)}
                    >
                        Edit
                    </Button>
                </Col>
                <Col>
                    <Button
                        style={{ borderRadius: "5px", height: '30px', margin: '8px', backgroundColor: "#F47D11", fontWeight: 800, border: "none" }}
                        onClick={() => setShowDeleteConfirmation(true)}
                    >
                        Delete
                    </Button>
                </Col>
            </Row>
            {showEditAttendance && (
                <EditLeaveAttendance
                    showEditAttendance={showEditAttendance}
                    closeEditAttendance={closeEditAttendance}
                    editAttendanceHandler={editAttendanceHandler}
                    leave={leave}
                    message={message}
                />
            )}
            {showDeleteConfirmation && (
                <DeleteLeaveAttendance
                    showDeleteConfirmation={showDeleteConfirmation}
                    closeDeleteConfirmation={closeDeleteConfirmation}
                    deleteAttendanceHandler={deleteAttendanceHandler}
                    leaveId={leave.id}
                    message={message}
                />
            )}
        </Card>
    );
};

const EditLeaveAttendance = ({
    showEditAttendance,
    closeEditAttendance,
    editAttendanceHandler,
    leave,
    message,
}) => {
    const [editDate, setEditDate] = useState(leave.date);
    const [editCode, setEditCode] = useState(leave.code);

    const handleEdit = () => {
        editAttendanceHandler(leave.id, editDate, editCode);
    };

    return (
        <Modal show={showEditAttendance} onHide={closeEditAttendance} centered>
            <Modal.Header closeButton>
                <Modal.Title>Edit Leave</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {message && <p className="text-success">{message}</p>}
                <Form>
                    <Form.Group controlId="editDate">
                        <Form.Label>Date</Form.Label>
                        <Form.Control
                            type="date"
                            value={editDate}
                            onChange={(e) => setEditDate(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="editCode">
                        <Form.Label>Code</Form.Label>
                        <Form.Control
                            type="text"
                            value={editCode}
                            onChange={(e) => setEditCode(e.target.value)}
                        />
                    </Form.Group>
                </Form>
                <div className="d-flex justify-content-end">
                    <Button
                        style={{ borderRadius: "5px", height: '40px', margin: '10px', backgroundColor: "#F47D11", fontWeight: 800, border: "none" }}
                        onClick={closeEditAttendance}
                        className="me-2"
                    >
                        Cancel
                    </Button>
                    <Button
                        style={{ borderRadius: "5px", height: '40px', margin: '10px', backgroundColor: "#F47D11", fontWeight: 800, border: "none" }}
                        onClick={handleEdit}
                    >
                        Save
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

const DeleteLeaveAttendance = ({
    showDeleteConfirmation,
    closeDeleteConfirmation,
    deleteAttendanceHandler,
    leaveId,
    message,
}) => {
    return (
        <Modal show={showDeleteConfirmation} onHide={closeDeleteConfirmation} centered>
            <Modal.Header closeButton>
                <Modal.Title>Confirm Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {message && <p className="text-danger">{message}</p>}
                <p>Are you sure you want to delete this leave record?</p>
                <div className="d-flex justify-content-end">
                    <Button
                        style={{ borderRadius: "5px", height: '40px', margin: '10px', backgroundColor: "#F47D11", fontWeight: 800, border: "none" }}
                        onClick={closeDeleteConfirmation}
                        className="me-2"
                    >
                        Cancel
                    </Button>
                    <Button
                        style={{ borderRadius: "5px", height: '40px', margin: '10px', backgroundColor: "#F47D11", fontWeight: 800, border: "none" }}
                        onClick={() => deleteAttendanceHandler(leaveId)}
                    >
                        Delete
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default Leave;
