import React, { useEffect, useState } from "react";
import {
    Alert,
    Button,
    Col,
    Form,
    Modal,
    Row,
    Table
} from "react-bootstrap";
import api from "../../config/api";
import { getRequestConfig } from "../../utils/getRequestConfig";

const AddDepartment = ({
    showAddDepartment,
    closeAddDepartment,
    addDepartmentHandler,
    message
}) => {
    const [departmentName, setDepartmentName] = useState("");
    const onSubmitHandler = (event) => {
        event.preventDefault();
        addDepartmentHandler(departmentName);
    };
    return (
        <Modal
            show={showAddDepartment}
            onHide={closeAddDepartment}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Add Department</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={onSubmitHandler}>
                    <Form.Group>
                        <Form.Label>Department Name</Form.Label>
                        <span style={{ color: "red" }}>*</span>
                        <Form.Control
                            type="text"
                            placeholder="Department name"
                            onChange={(event) => setDepartmentName(event.target.value)}
                            required
                        />
                    </Form.Group>

                    <hr />
                    {
                        message ? message : ""
                    }
                    <Button type="submit" style={{ borderRadius: "5px", height: '40px', margin: '5px', backgroundColor: "#F47D11", fontWeight: 800, border: "none" }}>
                        Add
                    </Button>
                    <Button
                        style={{ borderRadius: "5px", height: '40px', marginLeft: "25px", backgroundColor: "#F47D11", fontWeight: 800, border: "none" }}
                        onClick={closeAddDepartment}
                    >
                        Close
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

const EditDepartment = ({
    showEditDepartment,
    closeEditDepartment,
    editDepartmentHandler,
    department,
    message
}) => {
    const [departmentName, setDepartmentName] = useState(
        department.departmentName
    );

    const departmentId = department.id;

    const onSubmitHandler = (event) => {
        event.preventDefault();
        editDepartmentHandler(departmentId, departmentName);
    };
    return (
        <Modal
            show={showEditDepartment}
            onHide={closeEditDepartment}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Edit Department</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={onSubmitHandler}>
                    <Form.Group>
                        <Form.Label>Department Name</Form.Label>
                        <span style={{ color: "red" }}>*</span>
                        <Form.Control
                            type="text"
                            placeholder="department"
                            value={departmentName}
                            onChange={(event) => setDepartmentName(event.target.value)}
                            required
                        />
                    </Form.Group>
                    <hr />
                    {
                        message ? message : ""
                    }
                    <Button type="submit" style={{ borderRadius: "5px", height: '40px', margin: '5px', backgroundColor: "#F47D11", fontWeight: 800, border: "none" }}>
                        Update
                    </Button>
                    <Button
                        style={{ borderRadius: "5px", height: '40px', marginLeft: "25px", backgroundColor: "#F47D11", fontWeight: 800, border: "none" }}
                        onClick={closeEditDepartment}
                    >
                        Close
                    </Button>
                </Form>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
        </Modal>
    );
};


const DepartmentItems = ({ department, fetchDepartments, setMessage, message, index }) => {
    const [showEditDepartment, setShowEditDepartment] = useState(false);
    const closeEditDepartment = () => {
        setShowEditDepartment(false);
    };
    const openEditDepartment = () => {
        setShowEditDepartment(true);
    };

    const editDepartmentHandler = (departmentId, departmentName) => {
        let content = { departmentName: departmentName };
        api.put(`department/?id=${departmentId}`, content, getRequestConfig())
            .then((response) => {
                if (response.data.data) {
                    setShowEditDepartment(true);
                    fetchDepartments();
                    setMessage(<Alert variant="success">Department Edited Successfully</Alert>)
                }

            }).catch((ex) => {
                setMessage(<Alert variant="danger">There is some error</Alert>)
            });
    };
    return (
        <>
            <tr>
                <td>{index} </td>
                <td>{department.departmentName} </td>
                <td>
                    <Button style={{ borderRadius: "5px", height: '40px', marginRight: "10px", backgroundColor: "#F47D11", fontWeight: 800, border: "none" }} onClick={openEditDepartment}>
                        Edit
                    </Button>
                </td>
            </tr>

            <EditDepartment
                showEditDepartment={showEditDepartment}
                closeEditDepartment={closeEditDepartment}
                editDepartmentHandler={editDepartmentHandler}
                department={department}
                message={message}
            />
        </>
    );
};

const Department = () => {
    const [departments, setDepartments] = useState([]);
    const [showAddDepartment, setShowAddDepartment] = useState(false);
    const [message, setMessage] = useState("");
    useEffect(() => {
        fetchDepartments();
    }, []);

    //get task
    const fetchDepartments = () => {
        api.get("department/", getRequestConfig()).then((response) => {
            setDepartments(response.data);
            console.log(response.data);
        });
    };

    const closeAddDepartment = () => {
        setShowAddDepartment(false);
    };
    const openAddDepartment = () => {
        setShowAddDepartment(true);
    };

    const addDepartmentHandler = (departmentName) => {
        let content = { departmentName: departmentName };
        api.post("department/", content, getRequestConfig()).then((response) => {
            setShowAddDepartment(true);
            fetchDepartments();
            setMessage(<Alert variant="success">Department Added Successfully</Alert>)

        }).catch((ex) => {
            setMessage(<Alert variant="danger">There is some error</Alert>)
        });
    };

    setTimeout(() => {
        setMessage("");

    }, 10000);
    return (
        <div>
            <Row>
                <Col sm={12} md={6} lg={6} style={{ padding: "5px" }}>
                    {" "}
                    <h3>Department</h3>
                </Col>
                <Col sm={12} md={6} lg={6} style={{ padding: "5px" }}>
                    <Button onClick={openAddDepartment} style={{ borderRadius: "5px", height: '40px', marginLeft: "25px", backgroundColor: "#F47D11", fontWeight: 800, border: "none", float: "right" }} >
                        Add
                    </Button>
                </Col>
            </Row>

            <br />
            <Table className="table table-bordered">
                {departments.length === 0 && <h3>Loading...</h3>}
                <thead>
                    <tr>
                        <th>S.No.</th>
                        <th>Department</th>
                        <th>Edit</th>
                    </tr>
                </thead>
                <tbody>
                    {departments.length !== 0 &&
                        departments.map((department, index) => (
                            <DepartmentItems
                                key={index}
                                index={index + 1}
                                department={department}
                                fetchDepartments={fetchDepartments}
                                setMessage={setMessage}
                                message={message}
                            />
                        ))}
                </tbody>
            </Table>
            <AddDepartment
                showAddDepartment={showAddDepartment}
                closeAddDepartment={closeAddDepartment}
                addDepartmentHandler={addDepartmentHandler}
                message={message}
            />
        </div>
    );
};
export default Department;
