import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Form, FormControl, InputGroup, Row, Table } from "react-bootstrap";
import { FaSearch } from "react-icons/fa"; // Import search icon from react-icons
import { useHistory } from "react-router-dom";
import api from "../../config/api";
import { getRequestConfig } from "../../utils/getRequestConfig";
import Header from "../Header";

const Dashboard = ({ user, setUser, setShowGuestDashboard }) => {
    const [name, setName] = useState("");
    const [company, setCompany] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [expectedHours, setExpectedHours] = useState("");
    const [message, setMessage] = useState("");
    const [guests, setGuests] = useState([]); // Array to hold guest data
    const [searchTerm, setSearchTerm] = useState(""); // State for search input
    const history = useHistory();

    const registerHandler = () => {
        if (name && company && phoneNumber && expectedHours) {
            var today = new Date();
            var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() + ' ' + today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();

            const content = { name, company, mobileNumber: phoneNumber, expectedHour: expectedHours, date, checkIn: date, checkOut: '' };
            api.post(`guestAttendance/`, content, getRequestConfig())
                .then((response) => {
                    if (response.data.data) {
                        setMessage(<Alert variant="success">Registered Successfully</Alert>);
                        // Fetch updated guest list after registration
                        fetchGuests();
                        setCompany('')
                        setExpectedHours('')
                        setName('')
                        setPhoneNumber('')
                    }
                })
                .catch((ex) => {
                    setMessage(<Alert variant="danger">There was an error</Alert>);
                });
        } else {
            setMessage(<Alert variant="danger">Please fill in all fields!</Alert>);
        }
    };

    const fetchGuests = () => {
        api.get("guestAttendance/", getRequestConfig())
            .then((response) => {
                console.log(response.data.data);

                setGuests(response.data.data);
            })
            .catch((ex) => {
                setMessage(<Alert variant="danger">Failed to load guests</Alert>);
            });
    };

    const handleSearch = () => {
        // Filter guests based on the search term
        if (searchTerm) {
            const filteredGuests = guests.filter(guest =>
                guest.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setGuests(filteredGuests);
        } else {
            fetchGuests(); // Reset to original list if search term is empty
        }
    };

    const handleCheckout = (id) => {
        var tempDate = new Date();
        var date = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate() + ' ' + tempDate.getHours() + ':' + tempDate.getMinutes() + ':' + tempDate.getSeconds();

        let content = { checkout: date, status: "checkedout" };
        api.put(`guestAttendance/?guestId=${id}`, content, getRequestConfig())
            .then((response) => {
                fetchGuests();
                setMessage(<Alert variant="success">Checkout Successfully</Alert>);

            }).catch((ex) => {
                setMessage(<Alert variant="danger">There is some error</Alert>);
            });
        console.log(`Checkout for guest with ID: ${id}`);
    };
    const handleGuestLogout = () => {
        setShowGuestDashboard(false)
    }
    useEffect(() => {
        fetchGuests()
    }, [])

    return (
        <>
            <Header user={user} setUser={setUser} onGuestlogout={handleGuestLogout} />
            <div className="mx-3 mt-3">
                <h3>
                    {new Date().toLocaleDateString()} {new Date().toLocaleTimeString()}
                </h3>
                {message && message}

                <div className="card my-3 shadow p-3 mb-5 bg-body rounded">
                    <div className="card-body">
                        <Form>
                            {/* Existing form fields */}
                            <Form.Group controlId="formName">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter your name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </Form.Group>

                            <Form.Group controlId="formCompany">
                                <Form.Label>Company</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter your company"
                                    value={company}
                                    onChange={(e) => setCompany(e.target.value)}
                                />
                            </Form.Group>

                            <Form.Group controlId="formPhoneNumber">
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control
                                    type="tel"
                                    placeholder="Enter your phone number"
                                    value={phoneNumber}
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                />
                            </Form.Group>

                            <Form.Group controlId="formExpectedHours">
                                <Form.Label>Expected Hours</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter expected hours"
                                    value={expectedHours}
                                    onChange={(e) => setExpectedHours(e.target.value)}
                                />
                            </Form.Group>
                        </Form>
                    </div>
                </div>

                <Row>
                    <Col>
                        <Button
                            style={{
                                borderRadius: "5px",
                                height: '40px',
                                margin: '5px',
                                backgroundColor: "#F47D11",
                                fontWeight: 800,
                                border: "none"
                            }}
                            onClick={registerHandler}
                        >
                            REGISTER
                        </Button>
                    </Col>
                </Row>

                <br />

                {/* Search Input and Icon */}
                <InputGroup className="mb-3">
                    <FormControl
                        placeholder="Search by name"
                        aria-label="Search by name"
                        aria-describedby="basic-addon2"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <InputGroup.Append>
                        <Button variant="outline-secondary" onClick={handleSearch}>
                            <FaSearch />
                        </Button>
                    </InputGroup.Append>
                </InputGroup>

                {/* Table with guest data */}
                <div className="card my-3 shadow p-3 mb-5 bg-body rounded">
                    <div className="card-body">
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>SI.NO</th>
                                    <th>DATE</th>
                                    <th>NAME</th>
                                    <th>MOBILE</th>
                                    <th>EXPECTED HOURS</th>
                                    <th>ACTION</th>
                                </tr>
                            </thead>
                            <tbody>
                                {guests.filter(e => e.status === "checkedin").map((guest, index) => (
                                    <tr key={guest.id}>
                                        <td>{index + 1}</td>
                                        <td>{new Date(guest.date).toLocaleDateString()}</td>
                                        <td>{guest.name}</td>
                                        <td>{guest.mobile_number}</td>
                                        <td>{guest.expected_hour}</td>
                                        <td>
                                            <Button
                                                style={{
                                                    borderRadius: "5px",
                                                    height: '40px',
                                                    margin: '5px',
                                                    backgroundColor: "#F47D11",
                                                    fontWeight: 800,
                                                    border: "none"
                                                }}
                                                onClick={() => handleCheckout(guest.id)}
                                            >
                                                Checkout
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Dashboard;
