import React, { useEffect, useState } from "react";
import {
    Alert,
    Button,
    Col,
    Form,
    Modal,
    Row,
    Table
} from "react-bootstrap";
import api from "../../config/api";
import { getRequestConfig } from "../../utils/getRequestConfig";

const AddHoliday = ({
    showAddHoliday,
    closeAddHoliday,
    addHolidayHandler,
    message
}) => {
    const [year, setYear] = useState("");
    const [date, setDate] = useState("");
    const [title, setTitle] = useState("");

    const onSubmitHandler = (event) => {
        event.preventDefault();
        addHolidayHandler({ year, date, title });
    };

    return (
        <Modal
            show={showAddHoliday}
            onHide={closeAddHoliday}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Add Holiday</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={onSubmitHandler}>
                    <Form.Group>
                        <Form.Label>Year</Form.Label>
                        <span style={{ color: "red" }}>*</span>
                        <Form.Control
                            type="number"
                            placeholder="Year"
                            value={year}
                            onChange={(event) => setYear(event.target.value)}
                            required
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Date</Form.Label>
                        <span style={{ color: "red" }}>*</span>
                        <Form.Control
                            type="date"
                            placeholder="Date"
                            value={date}
                            onChange={(event) => setDate(event.target.value)}
                            required
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Title</Form.Label>
                        <span style={{ color: "red" }}>*</span>
                        <Form.Control
                            type="text"
                            placeholder="Title"
                            value={title}
                            onChange={(event) => setTitle(event.target.value)}
                            required
                        />
                    </Form.Group>
                    <hr />
                    {message ? message : ""}
                    <Button type="submit" style={{ borderRadius: "5px", height: '40px', margin: '5px', backgroundColor: "#F47D11", fontWeight: 800, border: "none" }}>
                        Add
                    </Button>
                    <Button
                        style={{ borderRadius: "5px", height: '40px', marginLeft: "25px", backgroundColor: "#F47D11", fontWeight: 800, border: "none" }}
                        onClick={closeAddHoliday}
                    >
                        Close
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

const EditHoliday = ({
    showEditHoliday,
    closeEditHoliday,
    editHolidayHandler,
    holiday,
    message
}) => {
    const [year, setYear] = useState(holiday.year);
    const [date, setDate] = useState(holiday.date);
    const [title, setTitle] = useState(holiday.title);

    const holidayId = holiday.id;

    const onSubmitHandler = (event) => {
        event.preventDefault();
        editHolidayHandler(holidayId, { year, date, title });
    };

    return (
        <Modal
            show={showEditHoliday}
            onHide={closeEditHoliday}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Edit Holiday</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={onSubmitHandler}>
                    <Form.Group>
                        <Form.Label>Year</Form.Label>
                        <span style={{ color: "red" }}>*</span>
                        <Form.Control
                            type="number"
                            placeholder="Year"
                            value={year}
                            onChange={(event) => setYear(event.target.value)}
                            required
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Date</Form.Label>
                        <span style={{ color: "red" }}>*</span>
                        <Form.Control
                            type="date"
                            placeholder="Date"
                            value={date}
                            onChange={(event) => setDate(event.target.value)}
                            required
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Title</Form.Label>
                        <span style={{ color: "red" }}>*</span>
                        <Form.Control
                            type="text"
                            placeholder="Title"
                            value={title}
                            onChange={(event) => setTitle(event.target.value)}
                            required
                        />
                    </Form.Group>
                    <hr />
                    {message ? message : ""}
                    <Button type="submit" style={{ borderRadius: "5px", height: '40px', margin: '5px', backgroundColor: "#F47D11", fontWeight: 800, border: "none" }}>
                        Update
                    </Button>
                    <Button
                        style={{ borderRadius: "5px", height: '40px', marginLeft: "25px", backgroundColor: "#F47D11", fontWeight: 800, border: "none" }}
                        onClick={closeEditHoliday}
                    >
                        Close
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};


const HolidayItems = ({ holiday, fetchHolidays, setMessage, message, index }) => {
    const [showEditHoliday, setShowEditHoliday] = useState(false);
    const closeEditHoliday = () => {
        setShowEditHoliday(false);
    };
    const openEditHoliday = () => {
        setShowEditHoliday(true);
    };

    const editHolidayHandler = (holidayId, holidayData) => {
        api.put(`holiday/?id=${holidayId}`, holidayData, getRequestConfig())
            .then((response) => {
                if (response.data.data) {
                    setShowEditHoliday(false);
                    fetchHolidays();
                    setMessage(<Alert variant="success">Holiday Edited Successfully</Alert>);
                }
            }).catch((ex) => {
                setMessage(<Alert variant="danger">There is some error</Alert>);
            });
    };

    return (
        <>
            <tr>
                <td>{index}</td>
                <td>{holiday.year}</td>
                <td>{holiday.date}</td>
                <td>{holiday.title}</td>
                <td>
                    <Button style={{ borderRadius: "5px", height: '40px', marginRight: "10px", backgroundColor: "#F47D11", fontWeight: 800, border: "none" }} onClick={openEditHoliday}>
                        Edit
                    </Button>
                </td>
            </tr>

            <EditHoliday
                showEditHoliday={showEditHoliday}
                closeEditHoliday={closeEditHoliday}
                editHolidayHandler={editHolidayHandler}
                holiday={holiday}
                message={message}
            />
        </>
    );
};

const Holiday = () => {
    const [holidays, setHolidays] = useState([]);
    const [showAddHoliday, setShowAddHoliday] = useState(false);
    const [message, setMessage] = useState("");
    console.log(holidays);

    useEffect(() => {
        fetchHolidays();
    }, []);

    const fetchHolidays = () => {
        api.get("holiday/", getRequestConfig()).then((response) => {
            setHolidays(response.data);
            console.log(response.data);
        });
    };

    const closeAddHoliday = () => {
        setShowAddHoliday(false);
    };
    const openAddHoliday = () => {
        setShowAddHoliday(true);
    };

    const addHolidayHandler = ({ year, date, title }) => {
        let content = { year, date, title };
        api.post("holiday/", content, getRequestConfig()).then((response) => {
            closeAddHoliday();
            fetchHolidays();
            setMessage(<Alert variant="success">Holiday Added Successfully</Alert>);
        }).catch((ex) => {
            setMessage(<Alert variant="danger">There is some error</Alert>);
        });
    };

    setTimeout(() => {
        setMessage("");
    }, 10000);

    return (
        <div>
            <Row>
                <Col sm={12} md={6} lg={6} style={{ padding: "5px" }}>
                    <h3>Holidays</h3>
                </Col>
                <Col sm={12} md={6} lg={6} style={{ padding: "5px" }}>
                    <Button onClick={openAddHoliday} style={{ borderRadius: "5px", height: '40px', marginLeft: "25px", backgroundColor: "#F47D11", fontWeight: 800, border: "none", float: "right" }}>
                        Add
                    </Button>
                </Col>
            </Row>

            <br />
            <Table className="table table-bordered">
                {holidays.length === 0 && <h3>Loading...</h3>}
                <thead>
                    <tr>
                        <th>S.No.</th>
                        <th>Year</th>
                        <th>Date</th>
                        <th>Title</th>
                        <th>Edit</th>
                    </tr>
                </thead>
                <tbody>
                    {holidays.length !== 0 &&
                        holidays.map((holiday, index) => (
                            <HolidayItems
                                key={index}
                                index={index + 1}
                                holiday={holiday}
                                fetchHolidays={fetchHolidays}
                                setMessage={setMessage}
                                message={message}
                            />
                        ))}
                </tbody>
            </Table>
            <AddHoliday
                showAddHoliday={showAddHoliday}
                closeAddHoliday={closeAddHoliday}
                addHolidayHandler={addHolidayHandler}
                message={message}
            />
        </div>
    );
};

export default Holiday;
