import React, { useState } from "react";
import { Alert, Button, Card, Col, Form, Row } from "react-bootstrap";
import api from "../config/api";
import ForgetPassword from "./ForgetPassword";
const AdminLogin = ({ setAdmin }) => {
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [showForgetPassword, setShowForgetPassword] = useState(false);
    const [message, setMessage] = useState("");
    //login submit
    const loginSubmit = (userName, password) => {

        let content = { userName: userName, password: password };

        if (userName !== '' && password !== '') {
            if (userName === "admin" && password === "admin@123") {

                let loggedAdmin = { name: 'admin', password: 'xxx', role: 'admin', status: 'active', token: 'xxyyzz' };
                localStorage.setItem("loggedAdmin", JSON.stringify(loggedAdmin));
                setAdmin(loggedAdmin);

            } else {
                setMessage(<Alert variant="danger">{"User Name/password is incorrect or user is inactive"}</Alert>);
            }
        }
        // api.post("admin/login/", content)
        //     .then((res) => {
        //         if (res.status === 200) {
        //             let loggedUser = JSON.stringify(res.data);
        //             localStorage.setItem("loggedUser", JSON.stringify(loggedUser));
        //             setUser(loggedUser);
        //             console.log(res.data);
        //         } else {
        //             setMessage(<Alert variant="danger">{"User Name/password is incorrect or user is inactive"}</Alert>);
        //             console.log(res.data);
        //         }
        //     })
        //     .catch((err) => {
        //         setMessage(<Alert variant="danger">{"User Name/password is incorrect or user is inactive"}</Alert>);

        //         console.log(err);
        //     });
    };

    const closeForgetPassword = () => {
        setShowForgetPassword(false);
    };
    const openForgetPassword = () => {
        setShowForgetPassword(true);
    };

    const forgetPassword = (userName, setAlert) => {
        api.put(`change_password/?userName=${userName}`).then((response) => {
            setShowForgetPassword(true);
            let content = {
                message: "Generated new Password",
                email: "Kindly Open your gmail account ",
            };
            if (response.status === 204) {
                setAlert(<small style={{ color: "red" }}>{"please give your correct user Id"}</small>);

            } else {
                setAlert(<small style={{ color: "green" }}>{content.message}<br />{content.email}</small>);
            }

        }).catch((err) => {

            setAlert(<small style={{ color: "red" }}>{"please give your correct user Id"}</small>);
        })
    };
    setTimeout(() => {
        setMessage("");

    }, 10000);
    return (
        <>

            <Row>

                <Col lg={8} className="left-section">
                    <img
                        src={process.env.PUBLIC_URL + "/images/icon.png"}
                        className="emblem"
                    />
                </Col>
                <Col lg={4} className="right-section">
                    {
                        message ? message : ""
                    }
                    <Card>
                        <Card.Header>
                            <h4 style={{ color: "#06508B" }}>
                                <i className="fa fa-key"></i> Admin Login
                            </h4>
                        </Card.Header>
                        <Card.Body>
                            <Form.Label style={{ color: "#06508B" }}>
                                <b>Username</b>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                value={userName}
                                onChange={(event) => setUserName(event.target.value)}
                            />
                            <br />
                            <Form.Label style={{ color: "#06508B" }}>
                                <b>Password</b>
                            </Form.Label>
                            <Form.Control
                                type="password"
                                value={password}
                                onChange={(event) => setPassword(event.target.value)}
                            />
                        </Card.Body>
                        <Card.Footer>
                            <Row>
                                <Col style={{ color: "#06508B" }}>
                                    <a onClick={openForgetPassword} style={{ cursor: "pointer" }}>
                                        forget Password ?
                                    </a>
                                </Col>
                                <Col style={{ textAlign: "right" }}>
                                    <Button onClick={() => loginSubmit(userName, password)} style={{ borderRadius: "5px", height: '40px', margin: '5px', backgroundColor: "#F47D11", fontWeight: 800, border: "none" }}>
                                        LOGIN
                                    </Button>
                                </Col>
                            </Row>
                        </Card.Footer>
                    </Card>
                </Col>
            </Row>
            <ForgetPassword
                showForgetPassword={showForgetPassword}
                closeForgetPassword={closeForgetPassword}
                forgetPassword={forgetPassword}
            />
        </>
    );
};
export default AdminLogin;
