import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const Header = ({ user, setUser, admin, setAdmin, onGuestlogout }) => {

    let name = user && user === 'Guest' ? 'Guest' : (user && JSON.parse(user) ? JSON.parse(user).employee?.name : '');
    let path = window.location.href

    const logoutSubmit = () => {
        if (path.includes('admin')) {
            localStorage.removeItem("loggedAdmin");
            setAdmin("");
        } else if (user === 'Guest' && onGuestlogout) {
            console.log('Guest');

            onGuestlogout()
        } else {
            localStorage.removeItem("loggedUser");
            setUser("");
        }
    }

    const adminlogoutSubmit = () => {
        localStorage.removeItem("loggedAdmin");
        setAdmin("");
    }

    return (
        <>
            <Row className="header">
                <Col md={5} lg={3} className="d-flex justify-content-between">
                    <i className="fa fa-user-circle-o fa-3x"></i>

                    <h4 className="ms-3">
                        Welcome {path.includes('admin') ? 'Admin' : name}
                    </h4>
                </Col>
                <Col md={7} lg={9} style={{ textAlign: "right", paddingRight: "15px" }}>
                    {/* <Dropdown>
                        <Dropdown.Toggle nvariant="success" id="dropdown-basic">
                            Settings
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item><Link onClick={logoutSubmit} to="/">Logout</Link></Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown> */}
                    <Button
                        style={{ borderRadius: "5px", height: '40px', margin: '5px', backgroundColor: "#F47D11", fontWeight: 800, border: "none" }}>
                        <Link
                            onClick={logoutSubmit}
                            to={path.includes('admin') ? '/admin' : "/"}
                            style={{ color: "white", textDecoration: "none" }}
                        >
                            EXIT
                        </Link>
                    </Button>
                </Col>
            </Row>

        </>
    );
};
export default Header;
