import React, { useState } from "react";
import { Alert, Button, Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import api from "../config/api";
import GuestDashboard from "./guest/Dashboard";

const Login = ({ setUser }) => {
    const [employeeId, setEmployeeId] = useState("");
    const [message, setMessage] = useState("");
    let path = window.location.href;

    const [showGuestDashboard, setShowGuestDashboard] = useState(path.includes('guest') ? true : false);
    console.log(showGuestDashboard);

    // Handle keypad click
    const handleKeypadClick = (number) => {
        setEmployeeId((prev) => prev + number);
    };

    const handleBackspace = () => {
        setEmployeeId((prev) => prev.slice(0, -1));
    };

    // Login submit
    const loginSubmit = () => {
        const content = { employeeId: employeeId };

        api.post("login/", content)
            .then((res) => {
                if (res.status === 200) {
                    let loggedUser = JSON.stringify(res.data);
                    localStorage.setItem("loggedUser", JSON.stringify(loggedUser));
                    setUser(loggedUser);
                    console.log(res.data);
                } else {
                    setMessage(<Alert variant="danger">{"Login failed. Please check your information."}</Alert>);
                    console.log(res.data);
                }
            })
            .catch((err) => {
                setMessage(<Alert variant="danger">{"Login failed. Please check your information."}</Alert>);
                console.log(err);
            });
    };

    setTimeout(() => {
        setMessage("");
    }, 10000);

    return (
        <>
            {showGuestDashboard && <GuestDashboard setUser={setUser} user={'Guest'} setShowGuestDashboard={setShowGuestDashboard} />}
            {!showGuestDashboard && <Row style={{ height: '100vh', background: "#06508B" }} className="text-center">
                <Col lg={12} md={12} className="d-flex justify-content-center align-items-center">
                    <div className={`col-lg` ? `w-75 mx-auto` : `w-75 mx-auto`}>
                        {message ? message : ""}
                        <Card className="mb-3">
                            <div className="text-center my-5">
                                <img
                                    src={process.env.PUBLIC_URL + "/images/icon.png"}
                                    className="emblem"
                                    alt="Emblem"
                                />
                            </div>
                            <Card.Header>
                                <h4 style={{ color: "#06508B" }}>
                                    <i className="fa fa-key"></i> EMPLOYEE LOGIN (or){" "}
                                    <i className="fa fa-user"></i>{" "}
                                    <Link to="/guest/Dashboard" onClick={() => setShowGuestDashboard(true)} style={{ color: "#06508B", textDecoration: "none" }}>
                                        GUEST LOGIN
                                    </Link>
                                </h4>
                            </Card.Header>
                            <Card.Body>
                                <Form.Group>
                                    <Form.Label style={{ color: "#06508B", textAlign: "left", display: "block" }}>
                                        <b>CARD ID / NO (KORTIN ID / NO)</b>
                                    </Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            type="text"
                                            value={employeeId}
                                            onChange={() => { }}
                                        />
                                    </InputGroup>
                                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', marginTop: '10px' }}>
                                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 'Back'].map((num) => (
                                            <Button
                                                key={num}
                                                onClick={() => (num === 'Back' ? handleBackspace() : handleKeypadClick(num.toString()))}
                                                style={{ width: '80px', height: '40px', margin: '5px', backgroundColor: "#F47D11", fontWeight: 800, border: "none" }}
                                            >
                                                {num === 'Back' ? '⌫' : num}
                                            </Button>
                                        ))}
                                    </div>
                                </Form.Group>
                            </Card.Body>
                            <Card.Footer>
                                <Row>
                                    <Col style={{ textAlign: "right" }}>
                                        <Button
                                            style={{ borderRadius: "5px", height: '40px', margin: '5px', backgroundColor: "#0d79d2", fontWeight: 800, border: "none" }}
                                            onClick={() => loginSubmit()}
                                        >
                                            LOGIN
                                        </Button>
                                    </Col>
                                </Row>
                            </Card.Footer>
                        </Card>
                    </div>
                </Col>
            </Row>}
        </>

    );
};

export default Login;
