import jsPDF from "jspdf";
import 'jspdf-autotable';
import { default as React, useEffect, useState } from "react";
import { Alert, Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import api from "../../config/api";
import { countWeekdaysBetweenDates, formatDate, getShortDay, minutesToTime, timeToMinutes } from "../../functions";
import { getRequestConfig } from "../../utils/getRequestConfig";

// Function to convert minutes to 'HH:MM' time format
// function minutesToTime(minutes) {
//     const hours = Math.floor(minutes / 60);
//     const mins = minutes % 60;
//     return `${hours}:${mins < 10 ? '0' : ''}${mins}`;
// }

const Attendance = (userId) => {
    const [employees, setEmployees] = useState([]);
    console.log(employees);
    const [myAttendance, setMyAttendance] = useState([]);
    const [holidays, setHolidays] = useState([]);
    console.log(myAttendance);

    var today = new Date(),
        // get month in two digit
        month =
            (today.getMonth() + 1) < "10"
                ? "0" + (today.getMonth() + 1)
                : (today.getMonth() + 1),
        // get month in two digit
        previousday = (today.getDate() - 1) < "10" ? "0" + (today.getDate() - 1) : (today.getDate() - 1),
        currentday = today.getDate() < "10" ? "0" + today.getDate() : today.getDate(),
        // get current date
        yesterday = today.getFullYear() + "-" + month + "-" + previousday;
    today = today.getFullYear() + "-" + month + "-" + currentday;

    const [fromDate, setFromDate] = useState(localStorage.getItem(`fromDate1${userId}`) ? localStorage.getItem(`fromDate1${userId}`) : yesterday);
    const [toDate, setToDate] = useState(localStorage.getItem(`toDate1${userId}`) ? localStorage.getItem(`toDate1${userId}`) : today);
    const [employee, setEmployee] = useState(localStorage.getItem(`employee1${userId}`) ? localStorage.getItem(`employee1${userId}`) : "");

    let sumHours = 0;
    let sumMilliseconds = 0;
    let totalHoursSpend = 0;

    // const timeDiff = Math.abs((new Date(toDate)).getTime() - (new Date(fromDate)).getTime());
    // const dayDiff = Math.ceil(timeDiff / (1000 * 3600 * 24)) + 1;

    // Example usage:
    const dayDiff = countWeekdaysBetweenDates(fromDate, toDate, holidays);
    console.log(dayDiff);

    const getDatesInRange = (startDate, endDate) => {
        const dateArray = [];
        let currentDate = new Date(startDate);

        while (currentDate <= endDate) {
            dateArray.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() + 1);
        }

        return dateArray;
    }

    const dates = getDatesInRange(new Date(fromDate), new Date(toDate));
    // console.log(dates);

    useEffect(() => {
        fetchEmployees();
        fetchHolidays()
    }, []);

    useEffect(() => {
        fetchAttendance(employee, fromDate, toDate);
    }, [employee, fromDate, toDate]);

    const fetchEmployees = () => {
        api.get("employee/", getRequestConfig()).then((response) => {
            setEmployees(response.data.data);
        });
    };

    const fetchHolidays = () => {
        api.get("holiday/", getRequestConfig()).then((response) => {
            setHolidays(response.data);
            console.log(response.data);
        });
    };

    const resetFilter = (yesterday, today) => {

        localStorage.setItem(`employee1${userId}`, "");
        setEmployee(localStorage.getItem(`employee1${userId}`));
        localStorage.setItem(`fromDate1${userId}`, yesterday);
        setFromDate(localStorage.getItem(`fromDate1${userId}`));
        localStorage.setItem(`toDate1${userId}`, today);
        setToDate(localStorage.getItem(`toDate1${userId}`));
        setMyAttendance([]);
    };

    const sessionHandler = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        if (name === "employee") {
            localStorage.setItem(`employee1${userId}`, value);
            setEmployee(localStorage.getItem(`employee1${userId}`));
        }
        else if (name === "fromDate") {
            localStorage.setItem(`fromDate1${userId}`, value);
            setFromDate(localStorage.getItem(`fromDate1${userId}`));
        }
        else if (name === "toDate") {
            localStorage.setItem(`toDate1${userId}`, value);
            setToDate(localStorage.getItem(`toDate1${userId}`));
        }

    }
    const fetchAttendance = (employee, fromDate, toDate) => {

        if (employee !== "" && fromDate !== "" && toDate !== "") {
            api.get(
                `attendance/report/?startDate=${fromDate ? fromDate : localStorage.getItem(`fromDate1${userId}`)}&endDate=${toDate ? toDate : localStorage.getItem(`toDate1${userId}`)}&employeeId=${employee ? employee : localStorage.getItem(`employee1${userId}`)}`,
                getRequestConfig()
            )
                .then((response) => {
                    setMyAttendance(response.data);
                    console.log(response.data);
                });
        } else if (employee === "" && fromDate !== "" && toDate !== "") {
            api.get(
                `attendance/report/?startDate=${fromDate ? fromDate : localStorage.getItem(`fromDate1${userId}`)}&endDate=${toDate ? toDate : localStorage.getItem(`toDate1${userId}`)}`,
                getRequestConfig()
            )
                .then((response) => {
                    setMyAttendance(response.data);
                    console.log(response.data);
                });
        }

    };

    const GenerateAttendanceReportPdf = (myAttendance) => {
        var doc = new jsPDF({ format: "A4", orientation: "portrait" }); // Landscape orientation for more columns
        doc.setFont("times", "bold");

        if (employee !== '') {
            doc.setFontSize(14);
            doc.text("WORKING TIME REPORT", 80, 15);
            doc.setFontSize(13);
            doc.text(`Name       : ${myAttendance[0].employee}`, 20, 25);
            doc.text(`Card       : ${myAttendance[0].cardNumber}`, 20, 33);
            doc.text(`Department : ${myAttendance[0].depName}`, 20, 40);

            doc.setFontSize(10);

            const chunkArray = (array, chunkSize) => {
                const chunks = [];
                for (let i = 0; i < array.length; i += chunkSize) {
                    chunks.push(array.slice(i, i + chunkSize));
                }
                return chunks;
            };

            const formatTime = (date) => date ? `${date.getHours()}:${date.getMinutes()}` : "00:00";

            const dateChunks = chunkArray(dates, 7);
            const attendanceChunks = chunkArray(myAttendance, 7);

            const createTable = (doc, chunk, startY, dates) => {
                const head = [["", ...dates.map(item => {
                    const date = new Date(item);
                    return `${getShortDay(date)} ${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
                })]];

                let body = [];
                let totalHours = 0;

                const rowLabels = ["Shift Timing", "In-Out Time", "Expected Working hrs", "Actual Working hrs", "Excess hrs", "Shortage hrs", "Lunch Break", "Code"];
                rowLabels.forEach(label => {
                    const row = [label];
                    dates.forEach(d => {
                        const date = new Date(d);
                        const attExistIndex = myAttendance.findIndex(f => {
                            return formatDate(f.date) === formatDate(date);
                        });
                        const holidayExistIndex = holidays.findIndex(f => {
                            return formatDate(f.date) === formatDate(date);
                        });
                        const item = myAttendance[attExistIndex];
                        const holidayItem = holidays[holidayExistIndex];

                        if (attExistIndex > -1 && holidayExistIndex > -1) {
                            //if present in both att and holiday
                            const checkinDate = item.checkin ? new Date(item.checkin) : null;
                            const checkoutDate = item.checkout ? new Date(item.checkout) : null;

                            const checkinTime = checkinDate ? formatTime(checkinDate) : '00:00';
                            const checkoutTime = checkoutDate ? formatTime(checkoutDate) : '00:00';

                            let timeDifference = (checkinDate && checkoutDate) ? Math.abs(
                                checkoutDate.getTime() - checkinDate.getTime()
                            ) : 0;
                            let hours = (timeDifference / (1000 * 3600)).toFixed(2);

                            totalHours += Number(hours);

                            let shortageHrs = timeToMinutes(item.workingHours) - timeToMinutes(hours.toString().padStart(2, '0') + ':00');
                            let excessHrs = timeToMinutes(hours.toString().padStart(2, '0') + ':00') - timeToMinutes(item.workingHours);

                            switch (label) {
                                case "Shift Timing":
                                    row.push(item.shiftStartTime + "-" + item.shiftEndTime);
                                    break;
                                case "In-Out Time":
                                    row.push(`${checkinTime}-${checkoutTime}`);
                                    break;
                                case "Expected Working hrs":
                                    row.push(item.workingHours || "00:00");
                                    break;
                                case "Actual Working hrs":
                                    row.push(hours || "00:00");
                                    break;
                                case "Excess hrs":
                                    row.push(minutesToTime(Math.max(0, excessHrs)) || "00:00");
                                    break;
                                case "Shortage hrs":
                                    row.push(minutesToTime(Math.max(0, shortageHrs)) || "00:00");
                                    break;
                                case "Lunch Break":
                                    row.push("00:30");
                                    break;
                                case "Code":
                                    row.push(holidayItem.title);
                                    break;
                            }
                        }
                        else if (attExistIndex > -1 && holidayExistIndex === -1) {
                            const checkinDate = item.checkin ? new Date(item.checkin) : null;
                            const checkoutDate = item.checkout ? new Date(item.checkout) : null;

                            const checkinTime = checkinDate ? formatTime(checkinDate) : '00:00';
                            const checkoutTime = checkoutDate ? formatTime(checkoutDate) : '00:00';

                            let timeDifference = (checkinDate && checkoutDate) ? Math.abs(
                                checkoutDate.getTime() - checkinDate.getTime()
                            ) : 0;
                            let hours = (timeDifference / (1000 * 3600)).toFixed(2);

                            totalHours += Number(hours);

                            let shortageHrs = timeToMinutes(item.workingHours) - timeToMinutes(hours.toString().padStart(2, '0') + ':00');
                            let excessHrs = timeToMinutes(hours.toString().padStart(2, '0') + ':00') - timeToMinutes(item.workingHours);

                            switch (label) {
                                case "Shift Timing":
                                    row.push(item.shiftStartTime + "-" + item.shiftEndTime);
                                    break;
                                case "In-Out Time":
                                    row.push(`${checkinTime}-${checkoutTime}`);
                                    break;
                                case "Expected Working hrs":
                                    row.push(item.workingHours || "00:00");
                                    break;
                                case "Actual Working hrs":
                                    row.push(hours || "00:00");
                                    break;
                                case "Excess hrs":
                                    row.push(minutesToTime(Math.max(0, excessHrs)) || "00:00");
                                    break;
                                case "Shortage hrs":
                                    row.push(minutesToTime(Math.max(0, shortageHrs)) || "00:00");
                                    break;
                                case "Lunch Break":
                                    row.push("00:30");
                                    break;
                                case "Code":
                                    row.push(item.code);
                                    break;
                            }
                        } else if (attExistIndex === -1 && holidayExistIndex > -1) {
                            switch (label) {
                                case "Shift Timing":
                                    row.push('00:00-00:00');
                                    break;
                                case "In-Out Time":
                                    row.push('00:00-00:00');
                                    break;
                                case "Expected Working hrs":
                                    row.push("00:00");
                                    break;
                                case "Actual Working hrs":
                                    row.push("00:00");
                                    break;
                                case "Excess hrs":
                                    row.push("00:00");
                                    break;
                                case "Shortage hrs":
                                    row.push("00:00");
                                    break;
                                case "Lunch Break":
                                    row.push("00:00");
                                    break;
                                case "Code":
                                    row.push(holidayItem.title);
                                    break;
                            }
                        } else {
                            switch (label) {
                                case "Shift Timing":
                                    row.push('00:00-00:00');
                                    break;
                                case "In-Out Time":
                                    row.push('00:00-00:00');
                                    break;
                                case "Expected Working hrs":
                                    row.push("00:00");
                                    break;
                                case "Actual Working hrs":
                                    row.push("00:00");
                                    break;
                                case "Excess hrs":
                                    row.push("00:00");
                                    break;
                                case "Shortage hrs":
                                    row.push("00:00");
                                    break;
                                case "Lunch Break":
                                    row.push("00:00");
                                    break;
                                case "Code":
                                    row.push('');
                                    break;
                            }
                        }
                    });
                    body.push(row);
                });

                // Define column styles
                const columnStyles = {
                    0: { halign: 'left' } // Align the first column to the left
                };

                doc.autoTable({
                    head: head,
                    body: body,
                    styles: { fontSize: 8, halign: 'center' },
                    columnStyles: columnStyles,
                    headStyles: { fillColor: 'Lavender', textColor: 'black' },
                    bodyStyles: { textColor: 'black', lineWidth: 0.5, lineColor: ["black"] },
                    startY: startY,
                    margin: { horizontal: 10 },
                });

                return totalHours;
            };


            let currentPageY = 50;
            let totalHours = 0;
            dateChunks.forEach((chunk, index) => {
                const chunkTotalHours = createTable(doc, attendanceChunks[index] ? attendanceChunks[index] : [], currentPageY, dateChunks[index]);
                totalHours += chunkTotalHours;
                currentPageY += 70; // Adjust this value to add more space between tables
                if (currentPageY > 190) { // If the next table won't fit on the current page
                    doc.addPage();
                    currentPageY = 20; // Reset Y position for new page
                }
            });

            var workHourInMinutes = timeToMinutes(myAttendance[0].workingHours);
            var totalMinutes = workHourInMinutes * dayDiff;

            // Convert total minutes back to 'HH:MM' format
            const totalTime = minutesToTime(totalMinutes);
            const sumMinutes = sumMilliseconds / (1000 * 60);
            let totShortageHrs = totalMinutes - sumMinutes;
            let totExcessHrs = sumMinutes - totalMinutes;

            // Leave days time calculation
            const noOfLeavedays = myAttendance?.filter(f => f.code != '').length
            var totalLeaveInMinutes = workHourInMinutes * noOfLeavedays;
            const totalLeaveTime = minutesToTime(totalLeaveInMinutes);
            let leaveHrs = totalLeaveTime

            doc.autoTable({
                head: [
                    ['Expected.Hrs', 'Actual.Hrs', 'Excess.Hrs', 'Shortage.Hrs', 'Leave.Hrs'],
                    [
                        { content: totalTime, styles: { halign: 'center', fillColor: 'Lavender', textColor: 'black' } },
                        { content: minutesToTime(Math.max(0, sumMinutes)), styles: { halign: 'center', fillColor: 'Lavender', textColor: 'black' } },
                        { content: minutesToTime(Math.max(0, totExcessHrs)), styles: { halign: 'center', fillColor: 'Lavender', textColor: 'black' } },
                        { content: minutesToTime(Math.max(0, totShortageHrs)), styles: { halign: 'center', fillColor: 'Lavender', textColor: 'black' } },
                        { content: leaveHrs, styles: { halign: 'center', fillColor: 'Lavender', textColor: 'black' } },
                    ]
                ],
                headerStyles: { lineWidth: 0.5, lineColor: ["black"] },
                margin: { horizontal: 20 },
            });

            doc.save("Working Time Report.pdf");
        } else if (employee === '') {
            let isFirstEmployee = true;
            employees.forEach((emp, i) => {
                let empArray = myAttendance.filter(f => f.cardNumber === emp.card_number)
                if (!isFirstEmployee && empArray.length > 0) {
                    if (empArray.length > 0) doc.addPage();
                }
                if (empArray.length > 0) {

                    isFirstEmployee = false;
                    doc.setFontSize(14);
                    doc.text("WORKING TIME REPORT", 80, 15);
                    doc.setFontSize(13);
                    doc.text(`Name       : ${empArray[0].employee}`, 20, 25);
                    doc.text(`Card       : ${empArray[0].cardNumber}`, 20, 33);
                    doc.text(`Department : ${empArray[0].depName}`, 20, 40);

                    doc.setFontSize(10);

                    const chunkArray = (array, chunkSize) => {
                        const chunks = [];
                        for (let i = 0; i < array.length; i += chunkSize) {
                            chunks.push(array.slice(i, i + chunkSize));
                        }
                        return chunks;
                    };

                    const formatTime = (date) => date ? `${date.getHours()}:${date.getMinutes()}` : "00:00";

                    const dateChunks = chunkArray(dates, 7);
                    const attendanceChunks = chunkArray(empArray, 7);

                    const createTable = (doc, chunk, startY, dates) => {
                        const head = [["", ...dates.map(item => {
                            const date = new Date(item);
                            return `${getShortDay(date)} ${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
                        })]];

                        let body = [];
                        let totalHours = 0;

                        const rowLabels = ["Shift Timing", "In-Out Time", "Expected Working hrs", "Actual Working hrs", "Excess hrs", "Shortage hrs", "Lunch Break", "Code"];
                        rowLabels.forEach(label => {
                            const row = [label];
                            dates.forEach(d => {
                                const date = new Date(d);
                                const attExistIndex = empArray?.findIndex(f => {
                                    return formatDate(f.date) === formatDate(date);
                                });
                                const holidayExistIndex = holidays.findIndex(f => {
                                    return formatDate(f.date) === formatDate(date);
                                });
                                const item = empArray[attExistIndex];
                                const holidayItem = holidays[holidayExistIndex];

                                if (attExistIndex > -1 && holidayExistIndex > -1) {
                                    //if present in both att and holiday
                                    const checkinDate = item.checkin ? new Date(item.checkin) : null;
                                    const checkoutDate = item.checkout ? new Date(item.checkout) : null;

                                    const checkinTime = checkinDate ? formatTime(checkinDate) : '00:00';
                                    const checkoutTime = checkoutDate ? formatTime(checkoutDate) : '00:00';

                                    let timeDifference = (checkinDate && checkoutDate) ? Math.abs(
                                        checkoutDate.getTime() - checkinDate.getTime()
                                    ) : 0;
                                    let hours = (timeDifference / (1000 * 3600)).toFixed(2);

                                    totalHours += Number(hours);

                                    let shortageHrs = timeToMinutes(item.workingHours) - timeToMinutes(hours.toString().padStart(2, '0') + ':00');
                                    let excessHrs = timeToMinutes(hours.toString().padStart(2, '0') + ':00') - timeToMinutes(item.workingHours);

                                    switch (label) {
                                        case "Shift Timing":
                                            row.push(item.shiftStartTime + "-" + item.shiftEndTime);
                                            break;
                                        case "In-Out Time":
                                            row.push(`${checkinTime}-${checkoutTime}`);
                                            break;
                                        case "Expected Working hrs":
                                            row.push(item.workingHours || "00:00");
                                            break;
                                        case "Actual Working hrs":
                                            row.push(hours || "00:00");
                                            break;
                                        case "Excess hrs":
                                            row.push(minutesToTime(Math.max(0, excessHrs)) || "00:00");
                                            break;
                                        case "Shortage hrs":
                                            row.push(minutesToTime(Math.max(0, shortageHrs)) || "00:00");
                                            break;
                                        case "Lunch Break":
                                            row.push("00:30");
                                            break;
                                        case "Code":
                                            row.push(holidayItem.title);
                                            break;
                                    }
                                } else if (attExistIndex > -1 && holidayExistIndex === -1) {
                                    const checkinDate = item.checkin ? new Date(item.checkin) : null;
                                    const checkoutDate = item.checkout ? new Date(item.checkout) : null;

                                    const checkinTime = checkinDate ? formatTime(checkinDate) : '00:00';
                                    const checkoutTime = checkoutDate ? formatTime(checkoutDate) : '00:00';

                                    let timeDifference = (checkinDate && checkoutDate) ? Math.abs(
                                        checkoutDate.getTime() - checkinDate.getTime()
                                    ) : 0;
                                    let hours = (timeDifference / (1000 * 3600)).toFixed(2);

                                    totalHours += Number(hours);

                                    let shortageHrs = timeToMinutes(item.workingHours) - timeToMinutes(hours.toString().padStart(2, '0') + ':00');
                                    let excessHrs = timeToMinutes(hours.toString().padStart(2, '0') + ':00') - timeToMinutes(item.workingHours);

                                    switch (label) {
                                        case "Shift Timing":
                                            row.push(item.shiftStartTime + "-" + item.shiftEndTime);
                                            break;
                                        case "In-Out Time":
                                            row.push(`${checkinTime}-${checkoutTime}`);
                                            break;
                                        case "Expected Working hrs":
                                            row.push(item.workingHours || "00:00");
                                            break;
                                        case "Actual Working hrs":
                                            row.push(hours || "00:00");
                                            break;
                                        case "Excess hrs":
                                            row.push(minutesToTime(Math.max(0, excessHrs)) || "00:00");
                                            break;
                                        case "Shortage hrs":
                                            row.push(minutesToTime(Math.max(0, shortageHrs)) || "00:00");
                                            break;
                                        case "Lunch Break":
                                            row.push("00:30");
                                            break;
                                        case "Code":
                                            row.push(item.code);
                                            break;
                                    }
                                } else if (attExistIndex === -1 && holidayExistIndex > -1) {
                                    switch (label) {
                                        case "Shift Timing":
                                            row.push('00:00-00:00');
                                            break;
                                        case "In-Out Time":
                                            row.push('00:00-00:00');
                                            break;
                                        case "Expected Working hrs":
                                            row.push("00:00");
                                            break;
                                        case "Actual Working hrs":
                                            row.push("00:00");
                                            break;
                                        case "Excess hrs":
                                            row.push("00:00");
                                            break;
                                        case "Shortage hrs":
                                            row.push("00:00");
                                            break;
                                        case "Lunch Break":
                                            row.push("00:00");
                                            break;
                                        case "Code":
                                            row.push(holidayItem.title);
                                            break;
                                    }
                                } else {
                                    switch (label) {
                                        case "Shift Timing":
                                            row.push('00:00-00:00');
                                            break;
                                        case "In-Out Time":
                                            row.push('00:00-00:00');
                                            break;
                                        case "Expected Working hrs":
                                            row.push("00:00");
                                            break;
                                        case "Actual Working hrs":
                                            row.push("00:00");
                                            break;
                                        case "Excess hrs":
                                            row.push("00:00");
                                            break;
                                        case "Shortage hrs":
                                            row.push("00:00");
                                            break;
                                        case "Lunch Break":
                                            row.push("00:00");
                                            break;
                                        case "Code":
                                            row.push('');
                                            break;
                                    }
                                }
                            });
                            body.push(row);
                        });

                        // Define column styles
                        const columnStyles = {
                            0: { halign: 'left' } // Align the first column to the left
                        };

                        doc.autoTable({
                            head: head,
                            body: body,
                            styles: { fontSize: 8, halign: 'center' },
                            columnStyles: columnStyles,
                            headStyles: { fillColor: 'Lavender', textColor: 'black' },
                            bodyStyles: { textColor: 'black', lineWidth: 0.5, lineColor: ["black"] },
                            startY: startY,
                            margin: { horizontal: 10 },
                        });

                        return totalHours;
                    };

                    // const createTable = (doc, chunk, startY, dates) => {
                    //     const head = [["", ...dates.map(item => {
                    //         const date = new Date(item);
                    //         return `${getShortDay(date)} ${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
                    //     })]];

                    //     let body = [];
                    //     let totalHours = 0;

                    //     const rowLabels = ["Shift Timing", "In-Out Time", "Expected Working hrs", "Actual Working hrs", "Excess hrs", "Shortage hrs", "Lunch Break", "Code"];
                    //     rowLabels.forEach(label => {
                    //         const row = [label];
                    //         dates.forEach(d => {
                    //             const date = new Date(d);
                    //             const attExistIndex = empArray.findIndex(f => {
                    //                 return formatDate(f.date) === formatDate(date);
                    //             });
                    //             const item = empArray[attExistIndex];

                    //             if (attExistIndex > -1) {
                    //                 const checkinDate = item.checkin ? new Date(item.checkin) : null;
                    //                 const checkoutDate = item.checkout ? new Date(item.checkout) : null;

                    //                 const checkinTime = checkinDate ? formatTime(checkinDate) : '00:00';
                    //                 const checkoutTime = checkoutDate ? formatTime(checkoutDate) : '00:00';

                    //                 let timeDifference = (checkinDate && checkoutDate) ? Math.abs(
                    //                     checkoutDate.getTime() - checkinDate.getTime()
                    //                 ) : 0;
                    //                 let hours = (timeDifference / (1000 * 3600)).toFixed(2);

                    //                 totalHours += Number(hours);

                    //                 let shortageHrs = timeToMinutes(item.workingHours) - timeToMinutes(hours.toString().padStart(2, '0') + ':00');
                    //                 let excessHrs = timeToMinutes(hours.toString().padStart(2, '0') + ':00') - timeToMinutes(item.workingHours);

                    //                 switch (label) {
                    //                     case "Shift Timing":
                    //                         row.push(item.shiftStartTime + "-" + item.shiftEndTime);
                    //                         break;
                    //                     case "In-Out Time":
                    //                         row.push(`${checkinTime}-${checkoutTime}`);
                    //                         break;
                    //                     case "Expected Working hrs":
                    //                         row.push(item.workingHours || "00:00");
                    //                         break;
                    //                     case "Actual Working hrs":
                    //                         row.push(hours || "00:00");
                    //                         break;
                    //                     case "Excess hrs":
                    //                         row.push(minutesToTime(Math.max(0, excessHrs)) || "00:00");
                    //                         break;
                    //                     case "Shortage hrs":
                    //                         row.push(minutesToTime(Math.max(0, shortageHrs)) || "00:00");
                    //                         break;
                    //                     case "Lunch Break":
                    //                         row.push("00:30");
                    //                         break;
                    //                     case "Code":
                    //                         row.push(item.code);
                    //                         break;
                    //                 }
                    //             } else {
                    //                 switch (label) {
                    //                     case "Shift Timing":
                    //                         row.push('00:00-00:00');
                    //                         break;
                    //                     case "In-Out Time":
                    //                         row.push('00:00-00:00');
                    //                         break;
                    //                     case "Expected Working hrs":
                    //                         row.push("00:00");
                    //                         break;
                    //                     case "Actual Working hrs":
                    //                         row.push("00:00");
                    //                         break;
                    //                     case "Excess hrs":
                    //                         row.push("00:00");
                    //                         break;
                    //                     case "Shortage hrs":
                    //                         row.push("00:00");
                    //                         break;
                    //                     case "Lunch Break":
                    //                         row.push("00:00");
                    //                         break;
                    //                     case "Code":
                    //                         row.push('');
                    //                         break;
                    //                 }
                    //             }
                    //         });
                    //         body.push(row);
                    //     });

                    //     doc.autoTable({
                    //         head: head,
                    //         body: body,
                    //         styles: { fontSize: 8, halign: 'center' },
                    //         headStyles: { fillColor: 'Lavender', textColor: 'black' },
                    //         bodyStyles: { textColor: 'black', lineWidth: 0.5, lineColor: ["black"] },
                    //         startY: startY,
                    //         margin: { horizontal: 10 },
                    //     });

                    //     return totalHours;
                    // };

                    let currentPageY = 50;
                    let totalHours = 0;
                    dateChunks.forEach((chunk, index) => {
                        const chunkTotalHours = createTable(doc, attendanceChunks[index] ? attendanceChunks[index] : [], currentPageY, dateChunks[index]);
                        totalHours += chunkTotalHours;
                        currentPageY += 70; // Adjust this value to add more space between tables
                        if (currentPageY > 190) { // If the next table won't fit on the current page
                            doc.addPage();
                            currentPageY = 20; // Reset Y position for new page
                        }
                    });

                    //actual hrs
                    function sumTimeArray(timeArray) {
                        const totalMinutes = timeArray.reduce((sum, time) => sum + timeToMinutes(time), 0);
                        return minutesToTime(totalMinutes);
                    }
                    const timeArray = empArray.map(m => m.actualHours ? m.actualHours : '00:00')
                    const ActualHrs = sumTimeArray(timeArray);

                    var workHourInMinutes = timeToMinutes(empArray[0].workingHours);
                    var totalMinutes = workHourInMinutes * dayDiff;
                    // Convert total minutes back to 'HH:MM' format
                    const totalTime = minutesToTime(totalMinutes);//expected hrs
                    // const sumMinutes = sumMilliseconds / (1000 * 60);//old actual hrs
                    // let totShortageHrs = totalMinutes - sumMinutes;
                    // let totExcessHrs = sumMinutes - totalMinutes;

                    let totShortageHrs = timeToMinutes(totalTime) - timeToMinutes(ActualHrs);
                    let totExcessHrs = timeToMinutes(ActualHrs) - timeToMinutes(totalTime);

                    // Leave days time calculation
                    const noOfLeavedays = empArray?.filter(f => f.code != '').length;
                    var totalLeaveInMinutes = workHourInMinutes * noOfLeavedays;
                    const totalLeaveTime = minutesToTime(totalLeaveInMinutes);
                    let leaveHrs = totalLeaveTime;

                    doc.autoTable({
                        head: [
                            ['Expected.Hrs', 'Actual.Hrs', 'Excess.Hrs', 'Shortage.Hrs', 'Leave.Hrs'],
                            [
                                { content: totalTime, styles: { halign: 'center', fillColor: 'Lavender', textColor: 'black' } },
                                { content: ActualHrs, styles: { halign: 'center', fillColor: 'Lavender', textColor: 'black' } },
                                { content: minutesToTime(Math.max(0, totExcessHrs)), styles: { halign: 'center', fillColor: 'Lavender', textColor: 'black' } },
                                { content: minutesToTime(Math.max(0, totShortageHrs)), styles: { halign: 'center', fillColor: 'Lavender', textColor: 'black' } },
                                { content: leaveHrs, styles: { halign: 'center', fillColor: 'Lavender', textColor: 'black' } },
                            ]
                        ],
                        headerStyles: { lineWidth: 0.5, lineColor: ["black"] },
                        margin: { horizontal: 20 },
                    });

                }
            });
            doc.save("Working Time Report.pdf");
        }
    };

    return (
        <>
            <Row>
                <Col sm={12} md={6} lg={6} style={{ padding: "5px" }}>
                    {" "}
                    <h3>Attendance Report</h3>
                </Col>

            </Row>
            <br />
            <br />

            <Row style={{ fontWeight: "bold" }}>
                <Col>Employee</Col>
                <Col>From Date</Col>
                <Col>To Date</Col>
                <Col></Col>
            </Row>
            <Row>
                <Col>

                    <select
                        className="form-control"
                        required
                        name="employee"
                        onChange={sessionHandler}
                        value={employee}
                    >
                        <option value="">Select Employee</option>
                        {employees?.filter((item) => {
                            if (item.role !== "admin") return item;
                        })
                            .map((item, index) => {
                                return (
                                    <option key={index} value={item.id}>
                                        {item.name}
                                    </option>
                                );
                            })}
                    </select>
                </Col>
                <Col>
                    <input
                        type="date"
                        className="form-control"
                        value={fromDate}
                        name="fromDate"
                        onChange={sessionHandler}
                        required
                    />
                </Col>
                <Col>
                    <input
                        type="date"
                        className="form-control"
                        value={toDate}
                        name="toDate"
                        onChange={sessionHandler}
                        required
                    />
                </Col>
                <Col>
                    <Button
                        style={{ borderRadius: "5px", height: '40px', marginLeft: '10px', backgroundColor: "#F47D11", fontWeight: 800, border: "none" }}
                        onClick={() => resetFilter(yesterday, today)}
                    >
                        Reset
                    </Button>
                </Col>
            </Row>

            <br />
            {myAttendance.length > 0 && <Row>
                <Col className="text-end">
                    <Button
                        style={{ borderRadius: "5px", height: '40px', margin: '10px', backgroundColor: "#F47D11", fontWeight: 800, border: "none" }}
                        onClick={() => GenerateAttendanceReportPdf(myAttendance)}
                    >
                        Download PDF
                    </Button>
                </Col>
            </Row>}
            {
                myAttendance.length === 0 &&
                <Card style={{ fontWeight: "650" }}>
                    <Row>
                        <Col>No record found</Col>

                    </Row>
                </Card>
            }
            {myAttendance.length !== 0 && (
                <Card style={{ fontWeight: "650" }}>
                    <Row>
                        <Col>Date</Col>
                        <Col>Check-in</Col>
                        <Col>Check-out</Col>
                        <Col>Hours</Col>
                        <Col>Code</Col>
                        <Col>Edit</Col>
                    </Row>
                </Card>
            )}
            {myAttendance.length !== 0 && myAttendance?.map((item, index) => {

                let checkinDate = item.checkin !== null ? new Date(item.checkin) : "";
                let checkoutDate = item.checkout !== null ? new Date(item.checkout) : "";
                let timeDifference = (item.checkin !== null && item.checkout !== null) ? Math.abs(
                    checkoutDate.getTime() - checkinDate.getTime()
                ) : 0;
                let hours = (timeDifference / (1000 * 3600)).toFixed(2);

                sumHours = sumHours + Number(hours);
                sumMilliseconds = sumMilliseconds + Number(timeDifference);
                totalHoursSpend = (Number(totalHoursSpend) + Number(hours));
                return <AttendanceItem
                    attendance={item}
                    key={index}
                    fetchAttendance={fetchAttendance}
                    employee={employee}
                    fromDate={fromDate}
                    toDate={toDate}
                />;


            })}
            {myAttendance.length !== 0 && (
                <Card style={{ fontWeight: "650" }}>
                    <Row>
                        <Col></Col>
                        <Col></Col>
                        <Col style={{ textAlign: "left" }}>Total Hours : {" "}{(totalHoursSpend).toFixed(2)}</Col>
                        <Col></Col>
                    </Row>
                </Card>
            )}
        </>
    );
};

//edit Attendance model
const EditAttendance = ({
    showEditAttendance,
    closeEditAttendance,
    editAttendanceHandler,
    attendance,
    message
}) => {
    const [checkin, setCheckin] = useState(attendance.checkin);
    const [checkout, setCheckout] = useState(attendance.checkout);
    const [status, setStatus] = useState(attendance.status);
    const [comment, setComment] = useState(attendance.comment);
    const attendanceId = attendance.id;


    const onSubmitHandler = (event) => {
        event.preventDefault();
        editAttendanceHandler(
            checkin,
            checkout,
            status,
            comment,
            attendanceId
        );
    };
    return (
        <Modal
            show={showEditAttendance}
            onHide={closeEditAttendance}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Edit Attendance</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={onSubmitHandler}>

                    <Form.Group>
                        <Form.Label>Checkin</Form.Label>
                        <span style={{ color: "red" }}>*</span>
                        <Form.Control
                            type="text"
                            placeholder="checkin"
                            value={checkin}
                            onChange={(event) => setCheckin(event.target.value)}
                            required
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Checkout</Form.Label>
                        <span style={{ color: "red" }}>*</span>
                        <Form.Control
                            type="text"
                            placeholder="checkout"
                            value={checkout}
                            onChange={(event) => setCheckout(event.target.value)}
                            required
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Status</Form.Label>
                        <span style={{ color: "red" }}>*</span>
                        <Form.Control
                            as="select"
                            value={status}
                            onChange={(event) => setStatus(event.target.value)}
                        >
                            <option value="">select</option>
                            <option>checkedin</option>
                            <option>checkedout</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Comment</Form.Label>
                        <textarea
                            className="form-control"
                            placeholder="comment"
                            value={comment}
                            onChange={(event) => setComment(event.target.value)}
                        />
                    </Form.Group>
                    <hr />
                    {
                        message ? message : ""
                    }
                    <Button type="submit" style={{ borderRadius: "5px", height: '40px', margin: '5px', backgroundColor: "#F47D11", fontWeight: 800, border: "none" }}>
                        Update
                    </Button>
                    <Button
                        style={{ borderRadius: "5px", height: '40px', marginLeft: "25px", backgroundColor: "#F47D11", fontWeight: 800, border: "none" }}
                        onClick={closeEditAttendance}
                    >
                        Close
                    </Button>
                </Form>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
        </Modal>
    )
}
const AttendanceItem = ({
    attendance,
    fetchAttendance,
    employee,
    fromDate,
    toDate
}) => {

    const [showEditAttendance, setShowEditAttendance] = useState(false);
    const [message, setMessage] = useState("");
    const closeEditAttendance = () => {
        setShowEditAttendance(false);
    };
    const openEditAttendance = () => {
        setShowEditAttendance(true);
    };

    //edit attendance
    const editAttendanceHandler = (
        checkin,
        checkout,
        status,
        comment,
        attendanceId
    ) => {

        let content = {
            checkin: checkin,
            checkout: checkout,
            status: status,
            comment: comment
        }

        api.put(`attendance/report/?id=${attendanceId}`, content, getRequestConfig()).then((response) => {
            setShowEditAttendance(true);
            fetchAttendance(employee, fromDate, toDate);
            setMessage(<Alert variant="success">Attendance Edited Successfully</Alert>)

        }).catch((ex) => {
            setMessage(<Alert variant="danger">There is some error</Alert>)
        });
    }
    setTimeout(() => {
        setMessage("");

    }, 10000);

    const cardStyle = attendance.status === "checkedin" ? { backgroundColor: "#FFDE80" } : {};
    // const checkinDate = attendance.checkin !== null ? new Date(attendance.checkin) : '';

    // const dateString = attendance.date ? attendance.date : (checkinDate !== "" ?
    //     checkinDate.getDate() + "-" +
    //     (checkinDate.getMonth() + 1) + "-" +
    //     checkinDate.getFullYear() : "");

    // const checkoutDate = attendance.checkout !== null ? new Date(attendance.checkout) : "";
    // let timeDifference = checkoutDate !== "" && checkinDate !== "" ? Math.abs(checkoutDate.getTime() - checkinDate.getTime()) : "";
    // let hours = timeDifference !== "" ? msToTime(timeDifference) : "";

    const checkinDate = attendance.checkin ? new Date(attendance.checkin) : null;
    const dateString = attendance.date ? attendance.date : (checkinDate !== null ? `${checkinDate.getDate()}-${checkinDate.getMonth() + 1}-${checkinDate.getFullYear()}` : "");

    const checkoutDate = attendance.checkout ? new Date(attendance.checkout) : null;

    let timeDifference = 0;
    if (checkinDate && checkoutDate) {
        timeDifference = Math.abs(checkoutDate.getTime() - checkinDate.getTime());
    }
    let hours = (timeDifference / (1000 * 3600)).toFixed(2);

    return (
        <>
            <Card style={cardStyle}>
                <Row>
                    <Col>{dateString}</Col>
                    <Col>{checkinDate.getHours() + ":" + checkinDate.getMinutes()}</Col>
                    <Col>
                        {attendance.checkout
                            ? checkoutDate.getHours() + ":" + checkoutDate.getMinutes()
                            : ""}
                    </Col>
                    <Col>{hours} </Col>
                    <Col>{attendance.code} </Col>
                    <Col>
                        {attendance.code !== '' ? '' : <Button type="submit" style={{ borderRadius: "5px", height: '35px', marginLeft: "25px", backgroundColor: "#F47D11", fontWeight: 800, border: "none" }} onClick={openEditAttendance}>
                            Edit
                        </Button>}
                    </Col>
                </Row>
            </Card>
            <EditAttendance

                showEditAttendance={showEditAttendance}
                closeEditAttendance={closeEditAttendance}
                editAttendanceHandler={editAttendanceHandler}
                attendance={attendance}
                message={message}
            />
        </>
    );
};

export default Attendance;
