import React, { useState } from "react";
import { Col, Nav, Row } from "react-bootstrap";
import { Link, Route, Switch } from "react-router-dom";
import Header from "../Header";
import Attendance from "./Attendance";
import Department from "./Department";
import Employee from "./Employee";
import GuestAttendance from "./Guest";
import Holiday from "./Holiday";
import Home from "./Home";
import Leave from "./Leave";

const Dashboard = ({ admin, setAdmin }) => {
    const [selection, setSelection] = useState("Dashboard");
    // const userId = JSON.parse(user).employee.employee_id;
    return (
        <>
            <Header admin={admin} setAdmin={setAdmin} />

            <Row>
                <Col lg={1} className="side-nav" style={{ textAlign: "center" }}>
                    <Nav defaultActiveKey="/admin/home" className="flex-column">
                        <Link
                            to="/admin/home"
                            className={selection === "Dashboard" ? "text current" : "text"}
                            onClick={() => setSelection("Dashboard")}
                        >
                            <i className="fa fa-tachometer fa-3x"></i><br />
                            <span>Dashboard</span>
                        </Link>
                        {/* <br /> */}
                        <Link
                            to="/admin/attendance"
                            className={selection === "Attendance" ? "text current" : "text"}
                            onClick={() => setSelection("Attendance")}
                        >
                            <i className="fa fa-check-square-o fa-3x"></i><br />
                            <span>Report</span>
                        </Link>
                        <Link
                            to="/admin/department"
                            className={selection == "Department" ? "text current" : "text"}
                            onClick={() => setSelection("Department")}
                        >
                            <i class="fa fa-cubes fa-3x"></i><br />
                            <span>Department</span>
                        </Link>
                        <Link
                            to="/admin/employee"
                            className={selection == "Employee" ? "text current" : "text"}
                            onClick={() => setSelection("Employee")}
                        >
                            <i class="fa fa-users fa-3x"></i><br />
                            <span>Employee</span>
                        </Link>
                        <Link
                            to="/admin/holiday"
                            className={selection == "Holiday" ? "text current" : "text"}
                            onClick={() => setSelection("Holiday")}
                        >
                            <i class="fa fa-film fa-3x"></i><br />
                            <span>Holiday</span>
                        </Link>
                        <Link
                            to="/admin/guest"
                            className={selection == "Guest" ? "text current" : "text"}
                            onClick={() => setSelection("Guest")}
                        >
                            <i class="fa fa-book fa-3x"></i><br />
                            <span>Guest</span>
                        </Link>
                        <Link
                            to="/admin/leave"
                            className={selection == "Leave" ? "text current" : "text"}
                            onClick={() => setSelection("Leave")}
                        >
                            <i class="fa fa-print fa-3x"></i><br />
                            <span>Leave</span>
                        </Link>
                    </Nav>
                </Col>
                <Col lg={11} className="main">
                    <Switch>
                        <Route exact path="/admin/home" component={Home} />
                        <Route exact path="/admin/attendance" component={Attendance} />
                        <Route exact path="/admin/employee" component={Employee} />
                        <Route exact path="/admin/department" component={Department} />
                        <Route exact path="/admin/holiday" component={Holiday} />
                        <Route exact path="/admin/guest" component={GuestAttendance} />
                        <Route exact path="/admin/leave" component={Leave} />
                    </Switch>
                </Col>
            </Row>
        </>
    );
};
export default Dashboard;
